import React from "react";
import { VERIFICATION_CODE_SEND_FORM } from "../../../utils/constants";
import lang from "../../../utils/LanguagePacks/en-us.json";
import Indicator from "../../Indicator";
import Email from "../Email";
import { BiUser } from "react-icons/bi";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  email: string;
  showIndicator: { onEmail: boolean };
}

export default function VerificationCodeSenderForm({
  onChange,
  onSubmit,
  email,
  showIndicator,
}: Props) {
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-7rem)] relative">
      <div
        className="w-full max-w-md relative 
        shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)] 
        rounded-2xl p-6 sm:p-8 border border-gray-200 
        transform hover:scale-105 transition duration-300"
      >
        {/* User Icon Container */}
        <div
          className="absolute left-1/2 -top-12 md:-top-20 
          transform -translate-x-1/2 
          w-24 h-24 md:w-40 md:h-40 
          bg-navy-blue rounded-full 
          flex justify-center items-center 
          z-10"
        >
          <BiUser className="text-white-color text-4xl md:text-8xl" />
        </div>

        <form id={VERIFICATION_CODE_SEND_FORM} className="space-y-4 mt-16" onSubmit={onSubmit}>
          <div>
            <Email onChange={onChange} email={email} />
            {showIndicator?.onEmail && <Indicator message={lang.email_required} />}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Send verification code
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
