import axiosInstance from "../axios.config";

export default async function raiseQueryFuntion(query: { [key: string]: string }) {
  try {
    const { data } = await axiosInstance.post("/raise_ticket", query);
    return data;
  } catch (err) {
    throw err;
  }
}
