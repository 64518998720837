import React from "react";
import { PASSWORD } from "../../utils/constants";
import Indicator from "../Indicator";
import Password from "./Password";
import lang from "../../utils/LanguagePacks/en-us.json";
import { Link } from "react-router-dom";
import { ADMIN_FORGOT_PASSWORD, FORGOT_PASSWORD, REGISTER } from "../../utils/routes";
import Email from "./Email";
import { BiUser } from "react-icons/bi";

interface Props {
  email: string;
  password: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  showIndicator: { onEmail: boolean; onPass: boolean };
  onAdminPortal?: boolean;
}

export default function LoginForm({
  email,
  password,
  onChange,
  showIndicator,
  onSubmit,
  onAdminPortal,
}: Props) {
  const handleAppleSignIn = () => {
    console.log(`${process.env.REACT_APP_BACKEND_URL}/auth/apple`, "<<<>>>>>>>>>>><?>?<");
    // Redirect the user to the backend route to start the Apple Sign-In process
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/apple`;
  };
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-13rem)] md:min-h-[calc(100vh-16rem)] relative">
      <div
        className="w-full max-w-md relative 
        shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)] 
        rounded-2xl p-6 sm:p-8 border border-gray-200 
        transform hover:scale-105 transition duration-300"
      >
        {/* User Icon Container */}
        <div
          className="absolute left-1/2 -top-12 md:-top-20 
          transform -translate-x-1/2 
          w-24 h-24 md:w-32 md:h-32 
          bg-navy-blue rounded-full 
          flex justify-center items-center 
          z-10 md:hidden "
        >
          <BiUser className="text-white-color text-4xl md:text-6xl" />
        </div>
        <form className="space-y-4 mt-16 md:mt-6" onSubmit={onSubmit}>
          <div>
            <Email onChange={onChange} email={email} />
            {showIndicator?.onEmail && <Indicator message={lang.email_required} />}
          </div>

          <div className="relative">
            <Password id={PASSWORD} onChange={onChange} value={password} placeholder="Password *" />
            {showIndicator?.onPass && <Indicator message={lang.pass_required} />}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="w-full py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              Sign in
            </button>
          </div>
        </form>
        <div className="w-full  my-1 flex justify-center">
          <span className="text-student-tax-secondary-theme-color text-lg self-center font-bold">
            OR
          </span>
        </div>
        {!onAdminPortal && (
          <Link
            to={`${process.env.REACT_APP_API_ENDPOINT}/auth/google`}
            className="flex flex-col items-center justify-center"
          >
            <img src="/Assets/continue_with_google_light.svg" alt="google" className="w-[60%]" />
          </Link>
        )}
        {/* <span onClick={handleAppleSignIn}> Login with apple </span> */}
        <Link
          className=" flex justify-center items-center"
          to={`${process.env.REACT_APP_API_ENDPOINT}/auth/apple`}
        >
          <img className="mt-4 w-4/5" src="/Assets/apple continue.png" alt="" />
        </Link>

        <div className="text-center py-4">
          <Link
            to={!onAdminPortal ? FORGOT_PASSWORD : ADMIN_FORGOT_PASSWORD}
            className="link text-student-tax-secondary-theme-color font-semibold"
          >
            Forgot password?
          </Link>
        </div>
        {!onAdminPortal && (
          <div className="py-4 text-center">
            {lang.not_registered}{" "}
            <Link
              to={REGISTER}
              className="link text-student-tax-secondary-theme-color font-semibold"
            >
              Sign up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
