import React, { useState, createContext, useEffect, useRef } from "react";
import { FaUser, FaSignature, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { devLogger } from "../utils/logger.config";
import UsernameUpdate from "../components/ProfileRelated/UpdateUsername/UsernameUpdate";
import EmailUpdate from "../components/ProfileRelated/UpdateEmail/EmailUpdate";
import PhoneUpdate from "../components/ProfileRelated/UpdatePhone/PhoneUpdate";
import PasswordUpdate from "../components/ProfileRelated/UpdatePassword/PasswordUpdate";
import { useUserDetail } from "../utils/customHooks";
import Dropdown from "../components/Dropdown";
import lang from "../utils/LanguagePacks/en-us.json";

export const UserDetailContext = createContext({
  username: "",
  email: "",
  phone: 0,
});
type MenuItem = {
  name: string;
  code: string;
};
const settingsMap: any = {
  "0": <UsernameUpdate />,
  "1": <EmailUpdate />,
  "2": <PhoneUpdate />,
  "3": <PasswordUpdate />,
};
const Menu: MenuItem[] = [
  { name: "Username", code: "0" },
  { name: "Email", code: "1" },
  { name: "Phone", code: "2" },
  { name: "Password", code: "3" },
];

export default function ProfileSettings() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<MenuItem>(Menu[0]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // Close dropdown when clicking outside

  const [currentSelectedSetting, setCurrentSelectedSetting] = useState("0"); // Set the first setting option as the current selected setting
  const userDetail = useUserDetail();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemSelect = (item: MenuItem) => {
    setSelectedItem(item);
    setIsOpen(false);
  };
  // Handle selecting a setting
  const selectSetting = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id: idx } = e.currentTarget;
    devLogger.log(`Clicked idx: ${idx}`);

    setCurrentSelectedSetting(idx);
  };
  return (
    <UserDetailContext.Provider value={userDetail}>
      <div className="h-[100vh] page hidden md:block">
        <div className="flex h-[100%]">
          <section className="w-[20rem] border-r border-l tb-border pt-6">
            <h2 className="border-b tb-border flex items-center gap-4 px-4 py-4">
              <FaUser /> Profile Settings
            </h2>

            <ul>
              <li
                onClick={selectSetting}
                id={"0"}
                className={`name-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "0" &&
                  "bg-student-tax-primary-theme-color cursor-pointer text-white-color"
                } `}
              >
                <FaSignature />
                Username
              </li>
              <li
                onClick={selectSetting}
                id={"1"}
                className={`email-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "1" &&
                  "bg-student-tax-primary-theme-color  text-white-color"
                } cursor-pointer `}
              >
                <MdEmail />
                Email
              </li>
              <li
                onClick={selectSetting}
                id={"2"}
                className={`phone-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "2" &&
                  "bg-student-tax-primary-theme-color  text-white-color"
                } cursor-pointer `}
              >
                <FaPhoneAlt />
                Phone
              </li>
              <li
                onClick={selectSetting}
                id={"3"}
                className={`password-change px-4 py-4 font-semibold flex items-center gap-4 ${
                  currentSelectedSetting === "3" &&
                  "bg-student-tax-primary-theme-color  text-white-color"
                } cursor-pointer `}
              >
                <RiLockPasswordFill />
                Password
              </li>
            </ul>
          </section>
          <section className="w-[100%] px-4 py-6">{settingsMap[currentSelectedSetting]}</section>
        </div>
      </div>
      <div className="block md:hidden px-3">
        <h2 className="border-b tb-border flex items-center gap-4 px-4 py-4">
          <FaUser /> Profile Settings
        </h2>

        <div className="relative " ref={dropdownRef}>
          {/* Dropdown Trigger */}
          <button
            type="button"
            onClick={toggleDropdown}
            className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md shadow-sm 
                   focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                   hover:bg-gray-50 transition duration-200 ease-in-out"
          >
            <div className="flex justify-between items-center">
              <span className="text-black">{selectedItem.name}</span>
              <svg
                className={`w-5 h-5 transform transition-transform duration-200 ${
                  isOpen ? "rotate-180" : ""
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>

          {/* Dropdown Menu */}
          {isOpen && (
            <div
              className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg 
                     max-h-60 overflow-auto"
            >
              {Menu.map((item) => (
                <div
                  onClick={() => handleItemSelect(item)}
                  className={`px-4 py-2 text-sm text-gray-700 
                         hover:bg-gray-100 cursor-pointer 
                         transition duration-150 ease-in-out 
                         last:border-b-0 
                         ${selectedItem.code === item.code ? "bg-blue-50" : ""}`}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <section className="w-[100%]  py-6">{settingsMap[selectedItem.code]}</section>
      </div>
    </UserDetailContext.Provider>
  );
}
