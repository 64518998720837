import React, { useState } from "react";
import AdminLeftMenu from "../../components/AdminReated/AdminLeftMenu";
import UserContent from "../../components/AdminReated/AdminContent/UserContent";
import SettingContent from "../../components/AdminReated/AdminContent/SettingContent";
import DashboardContent from "../../components/AdminReated/AdminContent/DashboardContent";
import AdminManagement from "../../components/AdminReated/AdminContent/AdminManagement";
import PriceManagement from "../../components/AdminReated/AdminContent/PriceManagement";
import TicketManagement from "../../components/AdminReated/AdminContent/TicketManagement";

export default function AdminDashboard() {
  const [activeComponent, setActiveComponent] = useState("dashboard");
  const renderContent = () => {
    switch (activeComponent) {
      case "users":
        return <UserContent />;
      case "settings":
        return <SettingContent />;
      case "admin_management":
        return <AdminManagement />;
      case "price_management":
        return <PriceManagement />;
      case "ticket_management":
        return <TicketManagement />;
      default:
        return <DashboardContent />;
    }
  };
  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        <AdminLeftMenu setActiveComponent={setActiveComponent} />
        <div className="flex-1 p-6 overflow-y-auto">{renderContent()}</div>
      </div>
    </div>
  );
}
