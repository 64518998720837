import React, { useState } from "react";
import MainImg from "../components/MainImg";
import VerificationCodeSenderForm from "../components/AuthRelated/ResetPassword/VerificationCodeSenderForm";
import PasswordResetForm from "../components/AuthRelated/ResetPassword/PasswordResetForm";
import {
  ADMIN_ACCOUNT,
  EMAIL,
  PASSWORD,
  PASSWORD_RESET_FORM,
  USER_ACCOUNT,
  VERIFICATION_CODE,
  VERIFICATION_CODE_SEND_FORM,
} from "../utils/constants";
import Handler from "../classes/Handler";
import Validator from "../classes/Validator";
import PasswordResetData from "../interfaces/PasswordResetData.interface";
import { useNavigate } from "react-router-dom";
import { ADMIN_LOGIN, LOGIN } from "../utils/routes";
import { SHA256 } from "crypto-js";
import { devLogger } from "../utils/logger.config";

interface Props {
  accountType: string;
}

export default function ForgotPassword({ accountType }: Props) {
  const [verificationCodeSent, isVerificationCodeSent] = useState(false);
  const [passwordResetData, setPasswordResetData] = useState<PasswordResetData>({
    email: "",
    new_password: "",
    verification_code: "",
  });
  const [confirmPassVal, setConfirmPassVal] = useState("");
  const [showIndicator, toShowIndicator] = useState({
    onEmail: false,
    onPassword: false,
    onConfirmPassword: false,
    onVerificationCode: false,
  });
  const navigate = useNavigate();

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case EMAIL:
        // Remove any pre-displayed errors
        toShowIndicator({
          ...showIndicator,
          onEmail: false,
        });
        const isValidLength = value.length <= 100;
        isValidLength &&
          setPasswordResetData({
            ...passwordResetData,
            email: value,
          });
        break;

      case PASSWORD:
        // Remove any pre-displayed errors
        toShowIndicator({
          ...showIndicator,
          onPassword: false,
        });
        setPasswordResetData({
          ...passwordResetData,
          new_password: value,
        });
        break;

      case `CONFIRM_${PASSWORD}`:
        // Remove any pre-displayed errors
        toShowIndicator({
          ...showIndicator,
          onConfirmPassword: false,
        });
        setConfirmPassVal(value);
        break;

      case VERIFICATION_CODE:
        // Remove any pre-displayed errors
        toShowIndicator({
          ...showIndicator,
          onVerificationCode: false,
        });
        const isValidSize = value.length <= 6; // The valid size of OTP is of 5 chars
        const isValidCode = value ? Validator.validateNumberOnly(value) : true;
        isValidSize &&
          isValidCode &&
          setPasswordResetData({
            ...passwordResetData,
            verification_code: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle any submit event
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id: type } = e.currentTarget;

    switch (type) {
      case VERIFICATION_CODE_SEND_FORM:
        // Validate email
        const isValidEmail = Validator.validateEmail(passwordResetData.email);
        if (isValidEmail) {
          try {
            await Handler.handleSendingVerificationCode(passwordResetData.email, accountType);
            // await Handler.handleSendingVerificationCode(
            //   "z9p5ff9qq9@privaterelay.appleid.com",
            //   accountType
            // );
            isVerificationCodeSent(true);
            return;
          } catch (errstatus) {}
        }
        // Show err indicator
        toShowIndicator({
          ...showIndicator,
          onEmail: true,
        });
        break;

      case PASSWORD_RESET_FORM:
        // Validate password
        const isValidPass = Validator.validatePassword(passwordResetData.new_password);

        // Confirm password
        const match = confirmPassVal === passwordResetData.new_password;
        if (!isValidPass) {
          toShowIndicator({
            ...showIndicator,
            onPassword: true,
          });
          return;
        } else if (!match) {
          toShowIndicator({
            ...showIndicator,
            onConfirmPassword: true,
          });
          return;
        }

        // Handle resetting password
        const hash: string = SHA256(
          process.env.REACT_APP_ADMIN_NONCE + passwordResetData.new_password
        ).toString();
        const updatedResetData: PasswordResetData = {
          ...passwordResetData,
          new_password: hash,
        };
        try {
          await Handler.handlePasswordReset(
            // accountType === USER_ACCOUNT ? updatedResetData : passwordResetData,
            updatedResetData,
            accountType
          );
          // Navigate to the appropriate login page
          switch (accountType) {
            case USER_ACCOUNT:
              navigate(LOGIN);
              break;

            case ADMIN_ACCOUNT:
              navigate(ADMIN_LOGIN);
              break;

            default:
              break;
          }
        } catch (errstatus) {
          devLogger.error(errstatus);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className=" flex flex-col justify-center items-center mt-5">
      <div className="md:w-1/2 w-full px-2">
        {!verificationCodeSent ? (
          <VerificationCodeSenderForm
            email={passwordResetData.email}
            onChange={handleChange}
            onSubmit={handleSubmit}
            showIndicator={showIndicator}
          />
        ) : (
          <PasswordResetForm
            onChange={handleChange}
            onSubmit={handleSubmit}
            passResetData={passwordResetData}
            confirmPassVal={confirmPassVal}
            showIndicator={showIndicator}
          />
        )}
      </div>
    </div>
  );
}
