import faqData from "../../utils/FaqData";
import FileNowButton from "../Decorations/FileNowButton";
import Accordion from "./Accordian";
import lang from "../../utils/LanguagePacks/en-us.json";

function FAQSection() {
  return (
    <div className="relative md:py-20 md:px-52 px-2 ">
      <img className="mb-6 mt-12" src="/Assets/Line 3.svg" alt="" />
      <div className="flex md:flex-row flex-col items-center justify-between">
        <div className="flex md:flex-col flex-row gap-4 md:items-start items-center   md:w-[48%]">
          <img className="md:h-28 md:w-28 h-16 w-16" src="/Assets/FAQ.svg" alt="" />
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="text-student-tax-secondary-theme-color font-bold text-xl md:text-4xl font-serif">
                Frequently
              </span>
              <span className="text-student-tax-secondary-theme-color font-bold text-xl md:text-4xl font-serif">
                Asked Question
              </span>
            </div>
            <div className="bg-student-tax-primary-theme-color w-36 flex items-center justify-center text-white-color py-2 px-2 rounded-3xl">
              See All FAQ's
            </div>
          </div>
        </div>
        <div className="md:w-[48%] max-w-5xl md:mb-0 mb-12 flex flex-col">
          <Accordion items={faqData} />
          <div className="w-full flex justify-center">
            <FileNowButton text={lang.file_now_from_faq} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQSection;
