import React from "react";
import lang from "../../utils/LanguagePacks/en-us.json";
import EasyStepCard from "./EasyStepCard";
import FileNowButton from "../Decorations/FileNowButton";

const EasySteps = () => {
  return (
    <div className="relative md:py-20  ">
      <div className="flex flex-col items-center md:px-52 px-2 mt-12 ">
        <div className="flex items-center flex-col md:mb-16 mb-4  gap-2">
          <h2 className="md:text-2xl text-xl font-semibold text-text-gray dark:text-gray-300 font-mono text-center">
            {lang.three_easy_step}
          </h2>
          <h2 className="md:text-5xl text-3xl font-semibold text-student-tax-primary-theme-color font-mono text-center">
            {lang.three_easy_step2}
          </h2>
          <p className="text-text-gray dark:text-gray-300 md:text-xl text-base font-sans text-center">
            {lang.three_easy_step_desc}
          </p>
          <p className="text-text-gray dark:text-gray-300 md:text-xl text-base font-sans text-center">
            {lang.three_easy_step_desc2}
          </p>
          <p className="text-text-gray dark:text-gray-300 md:text-xl text-base font-sans text-center">
            {lang.three_easy_step_desc3}
          </p>
        </div>

        <img
          src="/Assets/right_side_image.svg"
          className="absolute top-20 right-0  h-80  self-end md:flex hidden"
          alt=""
        />
        <div className="w-full mb-5 flex flex-col justify-center">
          <div className="flex flex-row justify-between w-full md:gap-3 gap-1">
            <EasyStepCard value={lang.signup} image="/Assets/Signup Elipse.svg" />
            <EasyStepCard value={lang.fill_the_form} image="/Assets/Fill the form.svg" />

            <EasyStepCard value={lang.checkout} image="/Assets/Checkout.svg" />
          </div>
          <FileNowButton text={lang.file_now_from_easy_steps} />
        </div>
      </div>
    </div>
  );
};

export default EasySteps;
