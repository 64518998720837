export const EMAIL = "EMAIL";
export const PHONE = "PHONE";
export const NAME = "NAME";
export const PASSWORD = "PASSWORD";
export const VERIFICATION_CODE = "VERIFICATION_CODE";
export const QUERY = "QUERY";

// Themes
export const LIGHT = "light";
export const DARK = "dark";

export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";

// Cache types
export const LS = "LS";
export const SS = "SS";

// Password reset related
export const VERIFICATION_CODE_SEND_FORM = "VERIFICATION_CODE_SEND_FORM";
export const PASSWORD_RESET_FORM = "PASSWORD_RESET_FORM";

// Profile related
export const PROFILE_ICON = "PROFILE_ICON";
export const NEW_USERNAME = "NEW_USERNAME";
export const NEW_EMAIL = "NEW_EMAIL";
export const NEW_PHONE = "NEW_PHONE";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const CONFIRM_PASSWORD = "CONFIRM_PASSWORD";

// Account types
export const USER_ACCOUNT = "USER_ACCOUNT";
export const ADMIN_ACCOUNT = "ADMIN_ACCOUNT";

//Cookies Dismiss Value
export const COOKIE_POLICY_DISMISS = "cookiePolicyDismiss";

// Filing Status
export const NOT_STARTED = "not_started";
export const IN_PROGRESS = "in_progress";
export const FILED = "filed";

//Tax types value
export const RESIDENT = "resident";
export const NON_RESIDENT = "non-resident";

// default country
export const US = "US";

//IDS
export const NA = "NA";
export const ITIN = "ITIN";
export const SSN = "SSN";

// Documents Name
export const W2FORM = "w2Form";
export const FORM1099 = "form1099";
export const ANY_OTHER_INCOME = "anyOtherIncomeForm";
export const TUITION_STATEMENT = "tuitionStatement";
export const PASSPORT = "passport";
export const US_ENTRY_EXIT = "usEntryExitDates";
export const ALL_INCOME_FORM = "allIncomeForm";
export const VISA = "visa";
export const SSNDOCUMENT = "ssn";

//Document Type
export const TYPE_W2FORM = "w2";
export const TYPE_FORM1099 = "1099";
export const TYPE_TUITION_STATEMENT = "1098t";
export const TYPE_ANY_OTHER_INCOME = "other_income";
export const TYPE_PASSPORT = "passport";
export const TYPE_US_ENTRY_EXIT = "us_entry_exit";
export const TYPE_ALL_INCOME_FORM = "other_income";
export const TYPE_VISA = "immi_status";
export const TYPE_SSNDOCUMENT = "tin";

//Max Word length for Query
export const MAX_QUERY_LENGTH = 1000;

//TICKETS STATUS
export const OPEN_TICKET = "open";
export const IN_PROGRESS_TICKET = "in-progress";
export const CLOSED_TICKET = "closed";
