import React, { useState, useRef, useEffect } from "react";
import { BiSend } from "react-icons/bi";
import lang from "../utils/LanguagePacks/en-us.json";
import { MAX_QUERY_LENGTH } from "../utils/constants";
import Handler from "../classes/Handler";
import { devLogger } from "../utils/logger.config";
import { logEvent } from "../googleAnalytics/ga";
import { actions, category } from "../utils/CustomAnalytics";
import formatDateTime from "../utils/FormatDateAndTime";
import truncateText from "../utils/TruncateText";
import Loading from "../components/Decorations/Loading";
type Message = {
  id: number;
  text: string;
  type: "user" | "system";
  timestamp: string;
};

type Ticket = {
  _id: string;
  query: string;
  ticket_status: string;
  createdAt: string;
  isExpanded: boolean;
};

type ContactOption = "email" | "callback" | null;

const SupportPage = () => {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [selectedOption, setSelectedOption] = useState<ContactOption>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [previousTickets, setPreviousTickets] = useState<Ticket[]>([]);
  const [showPreviousTickets, setShowPreviousTickets] = useState(false);
  const [error, seterror] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);
  const handleSendMessage = () => {
    if (!message.trim()) return;

    const newMessage: Message = {
      id: Date.now(),
      text: message,
      type: "user",
      timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    };

    setChatMessages((prev) => [...prev, newMessage]);
    setMessage("");
    setShowOptions(true);
  };

  const handleOptionSelect = async (option: "email" | "callback") => {
    try {
      const query = {
        query: chatMessages[0].text,
        response_type: option,
      };
      await Handler.raiseQuery(query);
      logEvent(category.support, actions.support_success);
      setSelectedOption(option);
      const responseMessage: Message = {
        id: Date.now(),
        text: `${lang.query_success} ${option}.`,
        type: "system",
        timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      };
      setChatMessages((prev) => [...prev, responseMessage]);
      setShowOptions(false);
    } catch (error) {
      devLogger.error(error);
      let errorMessage = lang.unexpected_error;

      // Check if error is an object
      if (typeof error === "object" && error !== null) {
        if ("response" in error && typeof (error as any).response.data === "object") {
          const errorData = (error as any).response.data;

          // Check if the expected error message structure is present
          if (typeof errorData.Error === "string") {
            errorMessage = errorData.Error; // Set the extracted message
          } else {
            // Fallback for other possible structures
            errorMessage = errorData.message || errorMessage;
          }
        }
      }

      logEvent(category.form_update, actions.support_failed, errorMessage);
    } finally {
    }
  };
  const showPreviousQuery = async () => {
    setShowPreviousTickets(true);
    setIsLoading(true);
    try {
      const res = await Handler.getPrioviouslyRaisedTickets();
      if (res) {
        if (res && res.data) {
          const formattedTickets = res.data.map((ticket: any) => ({
            _id: ticket._id,
            query: ticket.query,
            ticket_status:
              ticket.ticket_status.charAt(0).toUpperCase() + ticket.ticket_status.slice(1),
            createdAt: ticket.createdAt,
            isExpanded: false,
          }));

          setPreviousTickets(formattedTickets);
          logEvent(category.support, actions.fetch_previous_query_success);
        }
      }
    } catch (error: any) {
      seterror(error?.response?.data?.Error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className=" rounded-lg shadow-lg ">
        <div className="flex border-b">
          <button
            className={`flex-1 p-4 text-center ${
              !showPreviousTickets
                ? "border-b-2 border-student-tax-secondary-theme-color text-student-tax-secondary-theme-color font-semibold"
                : "text-gray-500"
            }`}
            onClick={() => setShowPreviousTickets(false)}
          >
            {lang.raise_query}
          </button>
          <button
            className={`flex-1 p-4 text-center ${
              showPreviousTickets
                ? "border-b-2 border-student-tax-secondary-theme-color text-student-tax-secondary-theme-color font-semibold"
                : "text-gray-400"
            }`}
            onClick={() => showPreviousQuery()}
          >
            {lang.previous_query}
          </button>
        </div>

        <div className=" h-[550px] flex flex-col">
          {!showPreviousTickets ? (
            <>
              <div className="flex-1 overflow-y-auto p-4 space-y-4">
                <div className="bg-gray-100 dark:bg-light-black p-4 rounded-lg w-4/5">
                  <p className=" ">{lang.query_sorry_message}</p>
                </div>

                {chatMessages.map((msg) => (
                  <div className={`flex ${msg.type === "user" ? "justify-end" : "justify-start"}`}>
                    <div
                      className={`max-w-[80%] rounded-lg p-3 ${
                        msg.type === "user"
                          ? "bg-student-tax-secondary-theme-color text-white"
                          : "bg-gray-100 dark:bg-light-black"
                      } break-words`}
                    >
                      <p>{msg.text}</p>
                      <p
                        className={`text-xs mt-1 ${
                          msg.type === "user" ? "text-blue-100" : "text-gray-400 "
                        }`}
                      >
                        {msg.timestamp}
                      </p>
                    </div>
                  </div>
                ))}

                {showOptions && !selectedOption && (
                  <div className=" p-4 rounded-lg">
                    <p className="font-semibold mb-3">{lang.query_medium}</p>
                    <div className="space-y-2">
                      <button
                        className="w-full p-2 border border-student-tax-secondary-theme-color rounded-lg hover:bg-orange-50 dark:hover:bg-orange-950"
                        onClick={() => handleOptionSelect("email")}
                      >
                        {lang.email}
                      </button>
                      <button
                        className="w-full p-2 border border-student-tax-secondary-theme-color rounded-lg hover:bg-orange-50 dark:hover:bg-orange-950"
                        onClick={() => handleOptionSelect("callback")}
                      >
                        {lang.phone_call}
                      </button>
                    </div>
                  </div>
                )}
                <div ref={chatEndRef} />
              </div>

              {!showOptions && !selectedOption && (
                <div className="border-t p-4">
                  <div className="flex gap-2">
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type your message..."
                      className={` mt-1 block w-full border-gray-300 rounded-md focus:outline-none focus:ring-0 shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]`}
                      rows={3}
                      maxLength={MAX_QUERY_LENGTH}
                    />
                    <button
                      onClick={handleSendMessage}
                      disabled={!message.trim()}
                      className={`p-2 rounded-lg ${
                        message.trim()
                          ? "bg-student-tax-secondary-theme-color text-white"
                          : "bg-gray-200 text-gray-400"
                      }`}
                    >
                      <BiSend className="w-8 h-8" />
                    </button>
                  </div>
                  <div className="text-right text-xs text-gray-500 mt-1">
                    {message.length}/{MAX_QUERY_LENGTH}
                  </div>
                </div>
              )}

              {selectedOption && (
                <div className="p-4 text-center">
                  <button
                    onClick={() => {
                      setMessage("");
                      setChatMessages([]);
                      setSelectedOption(null);
                      setShowOptions(false);
                    }}
                    className="text-student-tax-secondary-theme-color text-lg font-thin underline"
                  >
                    {lang.raise_new_query}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="flex-1 overflow-y-auto p-4">
              <div>
                {!error && previousTickets ? (
                  <>
                    {previousTickets.map((ticket) => {
                      const { displayText, seeMoreNeeded } = ticket.isExpanded
                        ? { displayText: ticket.query, seeMoreNeeded: true }
                        : truncateText(ticket.query, 50);

                      const handleToggleExpand = () => {
                        // Toggle the expansion of the ticket
                        setPreviousTickets((prevTickets) =>
                          prevTickets.map((signleTicket) =>
                            signleTicket._id === ticket._id
                              ? { ...signleTicket, isExpanded: !signleTicket.isExpanded }
                              : signleTicket
                          )
                        );
                      };
                      return (
                        <div className="break-words shadow rounded-lg p-4 mb-4">
                          <div className="flex justify-between items-center mb-2">
                            <span className="text-sm text-gray-400">#{ticket._id}</span>
                            <span
                              className={`px-2 py-1 rounded-full text-xs ${
                                ticket.ticket_status === "open"
                                  ? "bg-green-500 text-white"
                                  : ticket.ticket_status === "Closed"
                                  ? "bg-gray-500 text-white"
                                  : "bg-yellow-500 text-white"
                              }`}
                            >
                              {ticket.ticket_status}
                            </span>
                          </div>
                          <p className="text-gray-700 dark:text-gray-200">
                            {displayText}

                            {/* Only show "See more" button if truncation is needed */}
                            {seeMoreNeeded && (
                              <span
                                className="text-student-tax-secondary-theme-color cursor-pointer text-sm font-medium"
                                onClick={handleToggleExpand}
                              >
                                {ticket.isExpanded ? "Show less" : "See more"}
                              </span>
                            )}
                            {/* {ticket.query} */}
                          </p>
                          <p className="text-sm text-gray-500 mt-2">
                            {formatDateTime(ticket.createdAt)}
                          </p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className=" flex justify-center">
                    <span className=" text-center font-bold text-2xl text-student-tax-secondary-theme-color">
                      {error}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default SupportPage;
