import React, { useLayoutEffect, useState } from "react";
import MainImg from "../components/MainImg";
import VerifyEmail from "../components/AuthRelated/VerifyEmail";
import LoginForm from "../components/AuthRelated/LoginForm";
import { EMAIL, PASSWORD, SS } from "../utils/constants";
import Validator from "../classes/Validator";
import LoginData from "../interfaces/LoginData.interface";
import Handler from "../classes/Handler";
import { SHA256 } from "crypto-js";
import { devLogger } from "../utils/logger.config";
import { logEvent } from "../googleAnalytics/ga";
import lang from "../utils/LanguagePacks/en-us.json";
import { actions, category, labels } from "../utils/CustomAnalytics";
import ProgressBar from "../components/ProgressBar";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import setIsAuthenticated from "../redux/actions/userAuthActions/setIsAuthenticated.action";
import Token from "../classes/Token";
import Cache from "../classes/Cache";
import Modal from "../components/Modal";

export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const access_token = queryParams.get("access_token") || undefined; // If missing, q will be undefined
  const refresh_token = queryParams.get("refresh_token") || undefined;
  const user_details = queryParams.get("user_details") || undefined;
  const login_error = queryParams.get("login_error") || undefined;
  const [notifyToVerifyEmail, toNotifyToVerifyEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [showErrorIndicator, toShowErrorIndicator] = useState({
    onEmail: false,
    onPass: false,
  });

  useLayoutEffect(() => {
    if (login_error) {
      handleOpenModal();
    } else {
      if (!access_token || !refresh_token || !user_details) {
        devLogger.error("Access token, refresh token, or user details are missing.");
        return;
      }

      // Save tokens
      Token.saveAccessToken("auth_token", access_token);
      Token.saveRefreshToken("refresh_token", refresh_token);

      // Normalize user details for consistency
      const normalizedUserDetails = normalizeUserDetails(user_details);

      // Save user details to session storage
      const sscache = new Cache(SS);
      sscache.save("user_detail", JSON.stringify(normalizedUserDetails));
      devLogger.log("User details saved!");

      // Dispatch authentication status
      dispatch(setIsAuthenticated(true));
    }
  }, [access_token, refresh_token, user_details, dispatch, login_error]);

  // Utility function to normalize user details
  const normalizeUserDetails = (details: any) => {
    if (typeof details === "string") {
      try {
        // Parse JSON string if needed
        return JSON.parse(details);
      } catch (error: any) {
        devLogger.error(error);
        return {};
      }
    }
    // Return details if already an object
    return details || {};
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case EMAIL:
        // Remove any existing err indicators
        toShowErrorIndicator({
          ...showErrorIndicator,
          onEmail: false,
        });
        setLoginData({
          ...loginData,
          email: value,
        });
        break;

      case PASSWORD:
        // Remove any existing err indicators
        toShowErrorIndicator({
          ...showErrorIndicator,
          onPass: false,
        });
        setLoginData({
          ...loginData,
          password: value,
        });
        break;

      default:
        break;
    }
  };

  // Handle login by form submission
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate email
    const isValidEmail = Validator.validateEmail(loginData.email);
    const passwordExists = loginData.password.length > 0;

    if (!isValidEmail && !passwordExists) {
      // Show error indicators
      toShowErrorIndicator({
        onEmail: true,
        onPass: true,
      });
      return;
    } else if (!isValidEmail) {
      toShowErrorIndicator({ ...showErrorIndicator, onEmail: true });
      return;
    } else if (!passwordExists) {
      toShowErrorIndicator({ ...showErrorIndicator, onPass: true });
      return;
    }

    // Handle user login
    const hash: string = SHA256(process.env.REACT_APP_NONCE + loginData.password).toString();
    devLogger.log(`Hashed password: ${hash}`);
    const updatedData: LoginData = {
      ...loginData,
      password: hash,
    };
    try {
      await Handler.handleLogin(updatedData);
      logEvent(category.auth, actions.login_success);
    } catch (errStatus: any) {
      devLogger.error(errStatus);
      if (errStatus === 400) {
        logEvent(category.auth, actions.login_failed, labels.wrong_creadentials);
      }
      if (errStatus === 500) {
        logEvent(category.auth, actions.login_failed, labels.server_error);
      }

      if (errStatus === 403) {
        logEvent(category.auth, actions.login_failed, labels.non_verified_email);
        try {
          await Handler.handleEmailVerification(loginData.email);
          toNotifyToVerifyEmail(true);
        } catch (errstatus) {
          devLogger.error(errStatus);
        }
      }
    }
  };

  return (
    <div className=" flex flex-col justify-center items-center mt-3">
      <div className="md:w-3/5 flex gap-3 flex-col items-center px-2 md:px-0 w-full">
        <ProgressBar />
        <div className="flex flex-row items-center gap-3">
          <img src=" /Assets/logo_box 2.svg" className="md:h-20 h-16 md:w-28 " alt="ires" />
          <img
            src=" /Assets/ea_bw_050118 1.svg"
            className="md:h-28 h-20 md:w-60 w-52 "
            alt="ires"
          />
        </div>
      </div>
      <div className="md:w-1/2 w-full px-2">
        {!notifyToVerifyEmail ? (
          <LoginForm
            email={loginData.email}
            password={loginData.password}
            onChange={handleChange}
            onSubmit={handleLogin}
            showIndicator={showErrorIndicator}
          />
        ) : (
          <VerifyEmail useremail={loginData.email} />
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={lang.error}
        content={lang.login_failed}
        buttonText={lang.try_again}
        type="error"
      />
    </div>
  );
}
