const routePageTitleMap: { [key: string]: string } = {
  "/home": "Home - Taxmate",
  "/history": "History - Taxmate",
  "/cart": "Cart - Taxmate",
  "/profile/settings": "Settings - Taxmate",
  "/login": "Login - Taxmate",
  "/register": "Registration - Taxmate",
  "/forgot-password": "Forget Password - Taxmate",
  "/support": "Support - Taxmate",
};
export default routePageTitleMap;
