import HomeForm from "../components/HomeForm";
import ProgressBar from "../components/ProgressBar";
import lang from "../utils/LanguagePacks/en-us.json"

export default function Home() {
  return (
    <>
      <div className="w-full flex justify-center items-center flex-col ">
      
        <div className="w-3/5 my-4">
          <ProgressBar />
        </div>
        <h1 className="mb-2">{lang.welcome}</h1>
      </div>
      <div>
        <HomeForm />
      </div>
    </>
  );
}
