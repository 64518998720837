import { Link } from "react-router-dom";

interface EasyStepCardProps {
  value: string;
  image: string;
}
function EasyStepCard({ value, image }: EasyStepCardProps) {
  return (
    <Link
      to="/register"
      className="w-full max-w-5xl bg-white-color relative border-[#e0e9e0] border rounded-3xl mb-4  py-3 cursor-pointer shadow-md hover:shadow-lg overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-[1.02] px-0"
    >
      <div className="flex flex-col items-center z-10 relative">
        <div className="md:h-40 md:w-40 h-16 w-16 border-[#f4e1cc] border rounded-full shadow-md mb-2 flex items-center justify-center">
          <img src={image} className="md:h-24 md:w-24 h-10 w-10" alt="Signup Elipse" />
        </div>
        <h3 className="font-medium text-student-tax-primary-theme-color md:text-3xl text-lg font-sans text-center ">
          {value}
        </h3>
      </div>
    </Link>
  );
}

export default EasyStepCard;
