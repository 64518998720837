import React from "react";
import Name from "../../AuthRelated/Name";
import Email from "../../AuthRelated/Email";
import Phone from "../../AuthRelated/Phone";
import { QUERY } from "../../../utils/constants";
import ContactUsData from "../../../interfaces/ContactUsData.interface";
import Indicator from "../../Indicator";
import lang from "../../../utils/LanguagePacks/en-us.json";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  contactusData: ContactUsData;
  showIndicator: { onEmail: boolean; onName: boolean; onQuery: boolean };
}

export default function ContactUsForm({ onChange, onSubmit, contactusData, showIndicator }: Props) {
  return (
    <form
      className="md:w-[50%]  shadow-[8px_8px_15px_rgba(0,0,0,0.3)] 
        rounded-2xl  p-5 border border-gray-200 md:p-8 bg-creame-color mb-2"
      onSubmit={onSubmit}
    >
      <div className="mt-4 flex flex-wrap gap-4">
        <div className="md:w-[48%] w-full">
          <Name onChange={onChange} name={contactusData.name} placeholder="Full name *" />
          {showIndicator.onName && <Indicator message={lang.required} />}
        </div>
        <div className="md:w-[48%] w-full">
          <Email onChange={onChange} email={contactusData.email} />
          {showIndicator.onEmail && <Indicator message={lang.email_required} />}
        </div>
        <div className="md:w-[48%] w-full">
          <Phone onChange={onChange} phone={contactusData.phone ?? 0} placeholder="Phone" />
        </div>
      </div>
      <div className="mt-4">
        <textarea
          name="query"
          id={QUERY}
          className="resize-none w-[100%] min-h-[10rem] text-base shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] focus:outline-none focus:ring-2 focus:ring-blue-500 border border-gray-300"
          placeholder="Write your query here *"
          onChange={onChange}
          value={contactusData.query}
        />
        {showIndicator.onQuery && <Indicator message={lang.required} />}
      </div>
      <div className="">
        <button
          className="btn-primary py-2 px-4 mt-4 bg-student-tax-secondary-theme-color font-semibold w-full md:w-auto"
          type="submit"
        >
          Send
        </button>
      </div>
    </form>
  );
}
