import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

export default async function AppleLogin(loginData: any) {
  try {
    const { data } = await axiosInstance.post("/auth/apple/callback", loginData);
    devLogger.log("Response from backend after calling the login API");
    devLogger.log(data);
    return data;
  } catch (err) {
    devLogger.error(err);
    throw err;
  }
}
