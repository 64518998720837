import React from "react";
import { Link } from "react-router-dom";
import { REGISTER } from "../../utils/routes";

export default function RegisterBtn() {
  return (
    <Link
      to={REGISTER}
      className="text-lg font-semibold font-sans rounded-xl px-6 py-1 border-2 flex items-center border-student-tax-secondary-theme-color text-white bg-student-tax-secondary-theme-color  hover:bg-white hover:text-student-tax-secondary-theme-color justify-center"
    >
      Sign up
    </Link>
  );
}
