import React, { useContext, useState } from "react";
import { NEW_PHONE, PASSWORD } from "../../../utils/constants";
import Formatter from "../../../classes/Formatter";
import Middleware from "../../../classes/Middleware";
import Password from "../../AuthRelated/Password";
import Handler from "../../../classes/Handler";
import Validator from "../../../classes/Validator";
import { prodLogger } from "../../../utils/logger.config";
import { UserDetailContext } from "../../../pages/ProfileSettings";

export default function PhoneUpdate() {
  const [requiredfields, setRequiredfields] = useState({
    password: "",
    phone: "",
  });
  const userdetail = useContext(UserDetailContext);
  // const dispatch = useDispatch();

  // Handle change in input fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case PASSWORD:
        setRequiredfields({
          ...requiredfields,
          password: value,
        });
        break;

      case NEW_PHONE:
        const isValidNumber = Validator.validateNumberOnly(Formatter.formatPhone(0, value));
        if (isValidNumber && value.length <= 14) {
          setRequiredfields({
            ...requiredfields,
            phone: Formatter.formatPhone(0, value),
          });
        }
        break;

      default:
        break;
    }
  };

  const nonEmptyFormSubmitCb = async (
    err: any,
    validatedFields?: {
      email?: string;
      hashedPass?: string;
      phone?: string;
    }
  ) => {
    if (err) {
      prodLogger.error(err);
      return;
    }
    if (validatedFields) {
      // Call the phone number update API handler
      await Handler.handlePhonenumberUpdate({
        password: validatedFields.hashedPass ?? "",
        phone_no: validatedFields.phone ? parseInt(validatedFields.phone) : 0,
      });
    }

    setRequiredfields({
      password: "",
      phone: "",
    });
  };

  return (
    <form
      onSubmit={(e) => {
        Middleware.processFormData(
          {
            requiredValues: [requiredfields.password, requiredfields.phone],
            evt: e,
            validate: {
              password: requiredfields.password,
              phone: requiredfields.phone,
            },
          },
          nonEmptyFormSubmitCb
        );
      }}
    >
      <div className="flex gap-6">
        <div className="md:w-[50%] w-full">
          <div className="flex flex-col gap-2 justify-center md:py-6 md:px-6 py-3 md:pb-0 pb-4">
            <label className="font-bold">Current phone number</label>
            <input
              type="text"
              disabled
              value={userdetail.phone ? Formatter.formatPhone(1, userdetail.phone.toString()) : ""}
              className="dark:bg-gray-600 outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]"
            />
          </div>

          <div className="flex flex-col gap-2 justify-center md:py-6 md:px-6 md:pb-0 pb-4">
            <label htmlFor={PASSWORD} className="font-bold">
              Password
            </label>
            <em>
              <p className="text-[1rem]">Type your password below</p>
            </em>
            <Password
              id={PASSWORD}
              onChange={handleChange}
              value={requiredfields.password}
              placeholder="Password *"
            />
          </div>

          <div className="flex flex-col gap-2 justify-center md:py-6 md:px-6 md:pb-0 pb-4">
            <label htmlFor={NEW_PHONE} className="font-bold">
              New phone number
            </label>
            <em>
              <p className="text-[1rem]">Type your new phone number below</p>
            </em>
            <input
              id={NEW_PHONE}
              type="text"
              value={Formatter.formatPhone(1, requiredfields.phone)}
              onChange={handleChange}
              autoComplete="off"
              placeholder="Phone number *"
              className="outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]"
            />
          </div>
        </div>
      </div>

      <div className="md:px-6 py-6">
        <button
          type="submit"
          className="w-full md:w-auto py-[0.85rem] px-4 rounded-xl bg-student-tax-secondary-theme-color hover:bg-white-color text-white hover:text-student-tax-secondary-theme-color font-semibold font-sans"
        >
          Change phone number
        </button>
      </div>
    </form>
  );
}
