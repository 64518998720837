export const category = {
  auth: "Auth",
  form_submit: "Form Submit",
  payment: "Payment",
  form_update: "Form Update",
  support: "Support",
};
export const actions = {
  login_failed: "LoginFailed",
  login_success: "LoginSuccess",
  registration_success: "RegistrationSuccess",
  registration_failed: "RegistrationFail",
  tax_details_success: "TaxDetailsSubmitted",
  tax_details_failed: "TaxDetailsSubmitFail",
  tax_details_update_success: "TaxDetailsUpdated",
  tax_details_update_failed: "TaxDetailsUpdateFail",
  payment_sheet_success: "PaymentSheetOpen",
  payment_sheet_failed: "PaymentSheetOpenFail",
  payment_success: "PaymentSuccess",
  payment_fail: "PaymentFail",
  support_success: "SupportSuccess",
  support_failed: "SupportFail",
  fetch_previous_query_success: "FetchPreviousQuerySuccess",
};
export const labels = {
  wrong_creadentials: "Wrong Creadentials",
  non_verified_email: "This Email is not Verified",
  server_error: "Internal Server Error",
};
