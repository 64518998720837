import { useEffect, useState } from "react";
import lang from "../../../utils/LanguagePacks/en-us.json";
import { CLOSED_TICKET, IN_PROGRESS_TICKET, OPEN_TICKET } from "../../../utils/constants";
import AdminHandler from "../../../classes/AdminHandler";
import { devLogger } from "../../../utils/logger.config";
// Types
interface User {
  name: string;
  email: string;
  phone: number | null;
}

interface Ticket {
  _id: string;
  query: string;
  ticket_status: "open" | "in-progress" | "closed";
  user_id: string;
  response_type: "callback" | "email";
  createdAt: string;
  user?: User;
}

type StatusFilterType = "all" | "open" | "in-progress" | "closed";

const TicketManagement: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [statusFilter, setStatusFilter] = useState<StatusFilterType>("open");
  const [error, seterror] = useState("");
  const [emailFilter, setEmailFilter] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);

  const fetchTickets = async (ticketStatus?: string, email?: string) => {
    seterror("");
    setTickets([]);
    try {
      const filters: Record<string, any> = {};
      if (ticketStatus && ticketStatus !== "all") {
        filters.ticket_status = ticketStatus;
      }
      if (email) {
        filters.email = email;
      }
      const res = await AdminHandler.handleGetRaisedTickets(filters);

      if (res) {
        setTickets(res?.data);
      }
    } catch (error: any) {
      seterror(error?.response?.data?.Error);
      devLogger.error(error?.response?.data?.Error);
    }
  };

  useEffect(() => {
    fetchTickets(OPEN_TICKET);
  }, []);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const applyEmailFilter = () => {
    if (emailFilter) {
      if (isValidEmail(emailFilter)) {
        setEmailError("");
        fetchTickets(statusFilter, emailFilter);
      } else {
        setEmailError("Please enter a valid email address");
        return;
      }
    } else {
      fetchTickets(statusFilter);
    }
  };
  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setStatusFilter(e.target.value as StatusFilterType);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailFilter(e.target.value);
    setEmailError("");
  };

  const handleTicketClick = async (ticket: Ticket) => {
    try {
      const res = await AdminHandler.handleGetQueryOwner(ticket.user_id);
      if (res) {
        setIsDetailModalOpen(true);
        setSelectedTicket({ ...ticket, user: res?.data });
      }
    } catch (error) {
      devLogger.error(error);
    }
  };
  const handleStatusUpdate = async (newStatus: Ticket["ticket_status"]) => {
    if (!selectedTicket) return;
    try {
      const data = {
        ticket_status: newStatus,
      };
      const res = await AdminHandler.handleChangeTicketStatus(selectedTicket?._id, data);
      if (res) {
        const updatedTickets = tickets.map((ticket) =>
          ticket._id === selectedTicket._id ? { ...ticket, ticket_status: newStatus } : ticket
        );
        setTickets(updatedTickets);
        setSelectedTicket({ ...selectedTicket, ticket_status: newStatus });
      }
    } catch (error) {
      devLogger.error(error);
    }
  };

  const getStatusColorClasses = (status: Ticket["ticket_status"]): string => {
    switch (status) {
      case OPEN_TICKET:
        return "bg-green-100 text-green-800";
      case IN_PROGRESS_TICKET:
        return "bg-yellow-100 text-yellow-800";
      case CLOSED_TICKET:
        return "bg-gray-100 text-gray-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">{lang.ticket_management}</h1>

      {/* Filters */}
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <select
          value={statusFilter}
          onChange={handleStatusChange}
          className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">{lang.all_tickets}</option>
          <option value={OPEN_TICKET}>{lang.open}</option>
          <option value={IN_PROGRESS_TICKET}>{lang.inprogress}</option>
          <option value={CLOSED_TICKET}>{lang.closed}</option>
        </select>

        <div className="flex-1">
          <div className="flex flex-row gap-3">
            <input
              type="email"
              placeholder="Filter by email"
              value={emailFilter}
              onChange={handleEmailChange}
              className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div
              onClick={applyEmailFilter}
              className="bg-blue-600 flex justify-center items-center px-6 rounded-md cursor-pointer"
            >
              <span className="text-white">Find</span>
            </div>
          </div>
          {emailError && <p className="mt-1 text-sm text-red-600">{emailError}</p>}
        </div>
      </div>
      {error && tickets.length === 0 ? (
        <div>
          <span>{error}</span>
        </div>
      ) : (
        <div className="overflow-x-auto bg-white rounded-lg shadow">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Query
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Response Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {tickets.length > 0 &&
                tickets.map((ticket) => (
                  <tr
                    // key={ticket._id}
                    onClick={() => handleTicketClick(ticket)}
                    className="hover:bg-gray-50 cursor-pointer transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">{ticket.query}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 py-1 text-sm rounded-full ${getStatusColorClasses(
                          ticket.ticket_status
                        )}`}
                      >
                        {ticket.ticket_status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap capitalize">
                      {ticket.response_type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(ticket.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Detail Modal */}
      {isDetailModalOpen && selectedTicket && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold">Ticket Details</h2>
                <button
                  onClick={() => setIsDetailModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              {selectedTicket?.user && (
                <div className="bg-gray-50 p-4 rounded-lg mb-6">
                  <h3 className="font-semibold mb-4">User Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Name</p>
                      <p className="font-medium">{selectedTicket.user.name}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Email</p>
                      <p className="font-medium">{selectedTicket.user.email}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Phone</p>
                      <p className="font-medium">{selectedTicket.user.phone}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Response Type</p>
                      <p className="font-medium capitalize">{selectedTicket.response_type}</p>
                    </div>
                  </div>
                </div>
              )}

              {/* Query */}
              <div className="mb-6">
                <h3 className="font-semibold mb-2">Query</h3>
                <p className="bg-gray-50 p-4 rounded-lg">{selectedTicket.query}</p>
              </div>

              {/* Status Management */}
              <div className="mb-6">
                <h3 className="font-semibold mb-4">Ticket Status</h3>
                <div className="flex flex-wrap gap-3">
                  {([OPEN_TICKET, IN_PROGRESS_TICKET, CLOSED_TICKET] as const).map((status) => (
                    <button
                      // key={key}
                      onClick={() => handleStatusUpdate(status)}
                      className={`px-4 py-2 rounded-md transition-colors ${
                        selectedTicket.ticket_status === status
                          ? "bg-blue-600 text-white"
                          : "bg-white border border-gray-300 hover:bg-gray-50"
                      }`}
                    >
                      {status.replace("-", " ").charAt(0).toUpperCase() + status.slice(1)}
                    </button>
                  ))}
                </div>
              </div>

              {/* Creation Date */}
              <div className="text-sm text-gray-500">
                Created: {new Date(selectedTicket.createdAt).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketManagement;
