const faqData = [
  {
    question: "When is the right time to file taxes?",
    answer:
      "The tax filing season typically starts in January and ends on April 15th of the processing year. We recommend filing as early as possible to avoid last-minute stress and ensure enough processing time.",
  },
  {
    question: "What documents do I need?",
    answer:
      "Typically, your W-2, 1098-T, 1099 and/or any other income statements that you received.",
  },
  {
    question: "What should I do if I receive another tax form after filing my return?",
    answer:
      "If you receive additional tax forms after filing, you may need to file an amended return. Contact your Taxmate, and he/she will guide you through the process.",
  },
  {
    question: "How long does the process take?",
    answer:
      "Most returns are completed within 5-10 business days.",
  },
  {
    question: "How much does it cost to file my taxes using Taxmate?",
    answer:
      "Our service costs just $39, offering great value for students and individuals with simple tax returns.",
  },
];
export default faqData