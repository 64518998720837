import React, { useEffect, useState } from "react";
import YearDropdown from "../YearDropdown";
import DataProps from "../../interfaces/CardData.interface";
import Handler from "../../classes/Handler";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../Decorations/Loading";
import lang from "../../utils/LanguagePacks/en-us.json";
import Modal from "../Modal";
import CartPageProps from "../../interfaces/CartPage.interface";
import { logEvent } from "../../googleAnalytics/ga";
import { actions, category } from "../../utils/CustomAnalytics";

function CartPage({ q, ty }: CartPageProps) {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the 'year' query parameter (if available)
  const searchParams = new URLSearchParams(location.search);
  const queryYear = searchParams.get("year");
  const currentYear = new Date().getFullYear(); //current year
  const [selectedYear, setSelectedYear] = useState(queryYear ? +queryYear : currentYear - 1);
  const [data, setData] = useState<DataProps | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paynowError, setPaynoyError] = useState<string>("");
  // for error
  const [isModalOpenError, setIsModalOpenError] = useState(false);

  //for failed payment
  const [isModalFiledPayment, setIsModalFiledPayment] = useState(false);
  // Simulate fetching data from an API
  useEffect(() => {
    const fetchData = async () => {
      setData(null);
      setError(null);
      setPaymentStatus(false);

      try {
        const response = await Handler.handleUserCart(+selectedYear);
        if (response) {
          if (response?.data?.paid) {
            setPaymentStatus(true);
          } else {
            setData(response.data);
          }
        }
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [queryYear, selectedYear]);
  useEffect(() => {
    if (ty && q === "failed") {
      logEvent(category.payment, actions.payment_fail);
      setIsModalFiledPayment(true);
      setPaymentStatus(false);
      setSelectedYear(+ty);
    }
  }, [ty, q]);

  // For Api Error
  const handleOpenModalError = () => setIsModalOpenError(true);
  const handleCloseModalError = () => setIsModalOpenError(false);

  //failed payment error
  const handleCloseModalPayment = () => setIsModalFiledPayment(false);

  if (loading) return <Loading />;
  //   if (error) return <p className="text-center text-lg text-red-500">{error}</p>;

  const { first_name, middle_name, last_name, tin_type, tin, itin_application } =
    data?.currentUserTaxDetails || {};

  const { currentPriceDetails = {} } = data || {};

  // Safely create fullName and formattedTin
  const fullName = [first_name, middle_name, last_name].filter(Boolean).join(" ");
  const formattedTin = tin ? `XXX-XX-${tin}` : "";

  const handlepayment = async () => {
    const dataToSend = {
      taxyear: selectedYear,
      amount: currentPriceDetails?.total,
    };
    try {
      const res = await Handler.handlePayment(dataToSend);
      logEvent(category.payment, actions.payment_sheet_success);
      if (res?.Message) {
        window.location.assign(res?.url);
      }
    } catch (error) {
      let errorMessage = lang.unexpected_error;

      // Check if error is an object
      if (typeof error === "object" && error !== null) {
        if ("response" in error && typeof (error as any).response.data === "object") {
          const errorData = (error as any).response.data;

          // Check if the expected error message structure is present
          if (typeof errorData.Error === "string") {
            errorMessage = errorData.Error; // Set the extracted message
          } else {
            // Fallback for other possible structures
            errorMessage = errorData.message || errorMessage;
          }
        }
      }

      setPaynoyError(errorMessage); // Set the extracted message
      logEvent(category.payment, actions.payment_sheet_failed, errorMessage);

      handleOpenModalError();
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <YearDropdown
        currentYear={currentYear}
        onYearChange={setSelectedYear}
        selectedYear={selectedYear}
      />
      {data && (
        <div className="flex flex-col md:flex-row justify-between items-stretch mt-10 bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden w-full max-w-6xl mx-auto">
          {/* Left Side - User Tax Details */}
          <div className="w-full md:w-1/2 p-6 bg-blue-50 dark:bg-blue-900 border-b md:border-b-0 md:border-r border-blue-200 flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-300 mb-4">
                {lang.user_details}
              </h2>
              <p className="text-lg mb-2">
                <strong>Full Name:</strong>
                <span className="ml-2 text-xl font-bold">{fullName}</span>
              </p>
              {tin_type !== "NA" && (
                <p className="text-lg mb-4">
                  <strong>{tin_type}:</strong> {formattedTin}
                </p>
              )}

              {itin_application && (
                <p className="text-lg mb-4">
                  <strong>ITIN Application:</strong> "Yes"
                </p>
              )}
            </div>
            <div className="flex justify-between">
              <button
                onClick={() => navigate(`/home?year=${selectedYear}&mode=preview`)}
                className="mt-6 hover:bg-blue-800 bg-blue-500 text-white py-2 px-4 rounded  transition duration-300"
              >
                {lang.preview}
              </button>
              <button
                onClick={() => navigate(`/home?year=${selectedYear}&mode="edit"`)}
                className="mt-6 hover:bg-blue-800 bg-blue-500  text-white py-2 px-4 rounded  transition duration-300"
              >
                {lang.edit}
              </button>
            </div>
          </div>

          {/* Right Side - Price and Payment Details */}
          <div className="w-full md:w-1/2 p-6 bg-green-50 dark:bg-green-900 flex flex-col justify-between">
            <h2 className="text-2xl font-semibold text-green-600 dark:text-green-300 mb-4">
              {lang.price_details}
            </h2>

            {/* Render price details */}
            {Object.entries(currentPriceDetails).map(([form, price]) => {
              if (form === "total") {
                return null;
              }
              return (
                <p className="text-lg mb-2">
                  <strong>{form}:</strong> ${price}
                </p>
              );
            })}

            {/* Total Cost Section */}
            <div className="border-t border-green-200 mt-4 pt-4">
              <h3 className="text-xl font-semibold text-green-600 dark:text-green-300">
                Total Cost:
              </h3>
              <p className="text-lg font-bold">
                <strong>${currentPriceDetails?.total}</strong>
              </p>
            </div>

            {/* Pay Now Button */}
            <button
              onClick={handlepayment}
              className="mt-6 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-300"
            >
              {lang.pay_now}
            </button>
          </div>
        </div>
      )}
      {paymentStatus && !data && (
        <div className="alert alert-warning mt-4 flex items-center flex-col">
          <p className="font-bold text-green-700 text-xl text-center">{lang.cartPaid}</p>
          <button
            className="w-1/4 py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none mt-3"
            onClick={() => navigate(`/history?year=${selectedYear}`)} // Assuming you are using react-router-dom for navigation
          >
            History
          </button>
        </div>
      )}
      <Modal
        isOpen={isModalOpenError}
        onClose={handleCloseModalError}
        title={lang.error}
        content={paynowError}
        buttonText={lang.fix_it}
        type="error"
      />
      <Modal
        isOpen={isModalFiledPayment}
        onClose={handleCloseModalPayment}
        title={lang.error}
        content={lang.payment_failed}
        buttonText={lang.try_again}
        type="error"
      />
      {error && (
        <div className=" flex justify-center mt-8 flex-col items-center gap-3">
          <span className="font-bold text-red-700 text-xl">{error}</span>
          {error !== lang.fetching_error_message && (
            <button
              className="w-1/4 py-2 bg-student-tax-primary-theme-color text-white font-medium rounded-3xl transition duration-200 shadow-lg hover:shadow-2xl focus:outline-none "
              onClick={() => navigate(`/home?year=${selectedYear}`)}
            >
              File Your Tax
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default CartPage;
