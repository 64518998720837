import React from "react";
interface WhyToChooseCardProps {
  value: string;
  image: string;
  description: string;
}
function WhyToChooseCard({ value, image, description }: WhyToChooseCardProps) {
  return (
    <div className="md:w-full max-w-5xl bg-white-color relative border-text-gray border rounded-2xl md:mb-4 mb-2 px-5 py-3 cursor-pointer shadow-md hover:shadow-lg overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-[1.02] ">
      <div className="flex flex-col items-center z-10 relative">
        <div className="md:h-28 md:w-28 h-24 w-24 border-[#f4e1cc] border rounded-full shadow-md mb-2 flex items-center justify-center">
          <img src={image} className="md:h-24 md:w-24 h-20 w-20" alt="Signup Elipse" />
        </div>
        <h3 className="font-medium text-student-tax-primary-theme-color md:text-3xl text-lg font-sans md:mb-2 text-center">
          {value}
        </h3>
        <p className="text-text-gray md:text-sm text-xs text-center font-sans font-medium">
          {description}
        </p>
      </div>
    </div>
  );
}

export default WhyToChooseCard;
