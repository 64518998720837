import React from "react";
import lang from "../../utils/LanguagePacks/en-us.json";
import WhyToChooseCard from "./WhyToChooseCard";
import FileNowButton from "../Decorations/FileNowButton";
function WhyToChoose() {
  return (
    <div className="md:px-52 px-2 flex flex-col items-center bg-why-to-choose-background ">
      <div className="mt-12 w-full flex flex-col items-center gap-2 md:mb-16 mb-4">
        <div className="flex  flex-row gap-2">
          <hr className="border-t-2 border-student-tax-secondary-theme-color md:w-44 md:my-6" />

          <h2 className="md:text-5xl text-3xl font-semibold text-student-tax-primary-theme-color font-mono text-center">
            {lang.why_to_choose}
          </h2>
          <hr className="border-t-2 border-student-tax-secondary-theme-color  md:w-44 md:my-6 text-center" />
        </div>
        <p className="text-text-gray md:text-xl text-base font-sans">{lang.why_to_choose_desc}</p>
      </div>
      <div className=" mb-14 ">
        <div className="flex md:flex-row justify-between w-full flex-col md:gap-3 gap-1">
          <WhyToChooseCard
            value={lang.why_choose_us_heading1}
            description={lang.why_choose_us_desc1}
            image="/Assets/whyChooseUs.svg"
          />
          <WhyToChooseCard
            value={lang.why_choose_us_heading2}
            description={lang.why_choose_us_desc2}
            image="/Assets/whyChooseUs.svg"
          />
          <WhyToChooseCard
            value={lang.why_choose_us_heading3}
            description={lang.why_choose_us_desc3}
            image="/Assets/whyChooseUs.svg"
          />
        </div>
        <div className="flex w-full  justify-center">
          <FileNowButton text={lang.file_now_from_why_choose_us} />
        </div>
      </div>
    </div>
  );
}

export default WhyToChoose;
