import { Dispatch } from "@reduxjs/toolkit";
import { devLogger } from "../utils/logger.config";
import Cookie from "./Cookie";
import StateSetter from "./StateSetter";
import Cache from "./Cache";
import { SS } from "../utils/constants";

export interface UserData {
  name: string;
  email: string;
  phone: number;
  password: string;
}

export default class User {
  private static isAuthenticated(): boolean {
    const authToken = Cookie.retrieveCookie("auth_token");
    devLogger.log(`AUTH_TOKEN: ${authToken}`);
    return authToken ? true : false;
  }

  static authorize(dispatch: any): void {
    if (this.isAuthenticated()) {
      devLogger.log("User is authenticated...logging in!");
      this.login(dispatch);
      return;
    }
    devLogger.log("User is not authenticated...logging out!");
    this.logout(dispatch);
  }

  static login(dispatch: any) {
    StateSetter.setIsUserAuthenticated(true, dispatch);
    // this.authorize(dispatch);
  }

  static logout(dispatch: Dispatch<any>) {
    // Manually remove the auth_token cookie
    document.cookie = `auth_token=; Expires=${new Date(0).toUTCString()}; Path=/; Domain=${
      window.location.hostname.includes("localhost") ? "" : process.env.REACT_APP_DOMAIN
    }; Secure; SameSite=Strict`;
    // Delete the refresh token from SS

    const cache = new Cache(SS);
    cache.remove("refresh_token");
    cache.remove("user_detail");

    // Update authentication state
    StateSetter.setIsUserAuthenticated(false, dispatch);
  }
}
