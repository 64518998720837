import React from "react";
import { Link } from "react-router-dom";
import { REGISTER } from "../../utils/routes";
interface FileButton {
  text: string;
}
function FileNowButton({ text }: FileButton) {
  return (
    <Link
      to={REGISTER}
      className="self-center w-52 flex justify-center items-center  bg-student-tax-secondary-theme-color  py-2   rounded-3xl"
    >
      <span className="text-white-color font-semibold">{text}</span>
    </Link>
  );
}

export default FileNowButton;
