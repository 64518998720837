import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import StudentTaxLogo from "./StudentTaxLogo";
import { MdMenu, MdClose } from "react-icons/md";
import NavbarText from "./navrelated/NavbarText";
import ThemeToggler from "./ThemeToggler";
import LoginBtn from "./Decorations/LoginBtn";
import RegisterBtn from "./Decorations/RegisterBtn";
import ProfilePanel from "./ProfileRelated/ProfilePanel";
import Cache from "../classes/Cache";
import { SS } from "../utils/constants";
import lang from "../utils/LanguagePacks/en-us.json";
import {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  HOME,
  PROFILE_SETTINGS,
  HISTORY,
  CART,
  CONTACT_US,
  SUPPORT,
  ADMIN_DASHBOARD,
  ADMIN_LOGIN,
} from "../utils/routes";
import { RiContactsLine, RiHome5Line, RiInformationLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import AdminNavbar from "./AdminReated/AdminNavbar";

const MobileNavbar = ({ isAuthenticated }: any) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({
    username: "",
    email: "",
  });
  const location = useLocation();
  const currentpath = location.pathname;

  useEffect(() => {
    const sscache = new Cache(SS);
    const userDetailStr: string | null = sscache.get("user_detail");
    if (userDetailStr) {
      const userDetailParsed: { username: string; email: string; phone: number } =
        JSON.parse(userDetailStr);
      setUserDetail({
        username: userDetailParsed.username,
        email: userDetailParsed.email,
      });
    }
  }, [currentpath]);

  // Close drawer when path changes
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [currentpath]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderDrawerContent = () => {
    // Conditional rendering based on login state
    if (currentpath === LOGIN || currentpath === REGISTER || currentpath === FORGOT_PASSWORD) {
      return (
        <div className="flex flex-col gap-6 p-6">
          <div className="flex flex-row items-center gap-2">
            <div className="border border-student-tax-secondary-theme-color rounded-full h-8 w-8 flex justify-center items-center bg-student-tax-secondary-theme-color">
              <RiHome5Line className="text-white-color font-semibold" />
            </div>
            <NavbarText value="Home" to={HOME} />
          </div>
          <Link to="/about" className="flex flex-row items-center gap-2">
            <div className="border border-student-tax-secondary-theme-color rounded-full h-8 w-8 flex justify-center items-center bg-student-tax-secondary-theme-color">
              <RiInformationLine className="text-white-color font-semibold" />
            </div>
            <NavbarText value="About Us" to="/about" />
          </Link>
          <div className="flex flex-row items-center gap-2">
            <div className="border border-student-tax-secondary-theme-color rounded-full h-8 w-8 flex justify-center items-center bg-student-tax-secondary-theme-color">
              <RiContactsLine className="text-white-color font-semibold" />
            </div>
            <NavbarText value="Contact Us" to={CONTACT_US} />
          </div>

          <div className="flex justify-center gap-4 flex-col ">
            <LoginBtn />
            <RegisterBtn />
          </div>
        </div>
      );
    }

    // Post-login content
    if ([HOME, PROFILE_SETTINGS, HISTORY, CART, SUPPORT].includes(currentpath)) {
      return (
        <div className="flex flex-col gap-6 p-6">
          <ProfilePanel username={userDetail.username} email={userDetail.email} />
        </div>
      );
    }

    // Default drawer content
    return (
      <div className="flex flex-col gap-6 p-6">
        <div className="flex flex-row items-center gap-2">
          <div className="border border-student-tax-secondary-theme-color rounded-full h-8 w-8 flex justify-center items-center bg-student-tax-secondary-theme-color">
            <RiHome5Line className="text-white-color font-semibold" />
          </div>
          <NavbarText value="Home" to={HOME} />
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="border border-student-tax-secondary-theme-color rounded-full h-8 w-8 flex justify-center items-center bg-student-tax-secondary-theme-color">
            <RiInformationLine className="text-white-color font-semibold" />
          </div>
          <NavbarText value="About Us" to="/about" />
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="border border-student-tax-secondary-theme-color rounded-full h-8 w-8 flex justify-center items-center bg-student-tax-secondary-theme-color">
            <RiContactsLine className="text-white-color font-semibold" />
          </div>
          <NavbarText value="Contact Us" to={CONTACT_US} />
        </div>

        <div className="flex justify-center gap-4 flex-col ">
          <LoginBtn />
          <RegisterBtn />
        </div>
      </div>
    );
  };

  return (
    <div className="md:hidden w-full">
      {" "}
      {/* Only show on mobile */}
      {/* Top Navigation Bar */}
      <div className="flex justify-between items-center bg-student-tax-primary-theme-color shadow-xl px-4 py-3 w-full">
        <StudentTaxLogo onAdmin={currentpath.includes("admin-portal")} />
        <button onClick={toggleDrawer} className="text-white text-2xl focus:outline-none">
          <MdMenu />
        </button>
      </div>
      {/* Drawer Overlay */}
      {isDrawerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={toggleDrawer} />
      )}
      {/* Drawer */}
      <div
        className={`
          fixed top-0 right-0 w-3/4 h-full bg-white 
          transform transition-transform duration-300 ease-in-out z-50
          ${isDrawerOpen ? "translate-x-0" : "translate-x-full"}
        `}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <Link
            to={`${isAuthenticated ? "/home" : "/"}`}
            className="text-student-tax-primary-theme-color font-serif "
          >
            {lang.web_name}
          </Link>

          <button onClick={toggleDrawer} className="text-2xl focus:outline-none">
            <MdClose className="text-student-tax-secondary-theme-color" />
          </button>
        </div>
        {renderDrawerContent()}
      </div>
    </div>
  );
};

const DesktopNavbar = ({ isAuthenticated }: any) => {
  const [userDetail, setUserDetail] = useState({
    username: "",
    email: "",
  });

  const location = useLocation();
  const currentpath = location.pathname;

  useEffect(() => {
    const sscache = new Cache(SS);
    const userDetailStr: string | null = sscache.get("user_detail");
    if (userDetailStr) {
      const userDetailParsed: { username: string; email: string; phone: number } =
        JSON.parse(userDetailStr);
      setUserDetail({
        username: userDetailParsed.username,
        email: userDetailParsed.email,
      });
    }
  }, [currentpath]);

  const getPathBasedComponent = () => {
    switch (currentpath) {
      case LOGIN:
      case REGISTER:
      case FORGOT_PASSWORD:
        return (
          <div className="flex items-center gap-4">
            <LoginBtn />
            <RegisterBtn />
          </div>
        );

      case HOME:
      case PROFILE_SETTINGS:
      case HISTORY:
      case CART:
      case SUPPORT:
        return (
          <div className="flex">
            <ProfilePanel username={userDetail.username} email={userDetail.email} />
          </div>
        );
      case ADMIN_DASHBOARD:
        return <AdminNavbar />;
      case ADMIN_LOGIN:
        return;
      default:
        return (
          <div className="flex items-center gap-4">
            <LoginBtn />
            <RegisterBtn />
          </div>
        );
    }
  };

  return (
    <div className="hidden md:flex justify-between items-center bg-student-tax-primary-theme-color shadow-xl px-10 py-4 w-full navbar">
      {/* Left Section: Logo */}
      <div className="flex-1">
        <StudentTaxLogo onAdmin={currentpath.includes("admin-portal")} />
      </div>

      {/* Middle Section: Centered Navbar Links */}
      <div className="flex-1 flex justify-center items-center">
        {(currentpath === "/" ||
          currentpath === LOGIN ||
          currentpath === REGISTER ||
          currentpath === FORGOT_PASSWORD) && (
          <div className="flex gap-10 items-center">
            <NavbarText value="Home" to={HOME} />
            <NavbarText value="About Us" to="/about" />
            <NavbarText value="Contact Us" to={CONTACT_US} />
            <ThemeToggler />
          </div>
        )}
      </div>

      {/* Right Section: Profile or Login/Register */}
      <div className="flex-1 flex justify-end items-center">{getPathBasedComponent()}</div>
    </div>
  );
};

export default function Navbar() {
  const isUserAuthenticated = useSelector((state: any) => state.isUserAuthenticated);
  return (
    <>
      <MobileNavbar isAuthenticated={isUserAuthenticated} />
      <DesktopNavbar isAuthenticated={isUserAuthenticated} />
    </>
  );
}
