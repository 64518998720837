function formatDateTime(isoDate: string): string {
  const date = new Date(isoDate);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  // Extract time components
  let hours = date.getHours(); // Get hours in 24-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight
  const formattedHours = String(hours).padStart(2, "0");

  // Return formatted string
  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${period}`;
}

export default formatDateTime;
