import React from "react";
import { Link } from "react-router-dom";
import { LOGIN } from "../../utils/routes";

export default function LoginBtn() {
  return (
    <Link
      to={LOGIN}
      className=" text-lg font-semibold font-sans rounded-xl px-6 py-1 border-2 flex items-center border-student-tax-secondary-theme-color text-student-tax-secondary-theme-color dark:bg-violet-900 bg-white  dark:text-white hover:bg-student-tax-secondary-theme-color hover:text-white"

    >
      Sign in
    </Link>
  );
}
