import React from "react";
import { Link } from "react-router-dom";

interface Props {
  onAdmin?: boolean;
}

export default function StudentTaxLogo({ onAdmin }: Props) {
  if (onAdmin)
    return (
      <div>
        <h1 className="text-white font-serif">TaxmateAdmin</h1>
      </div>
    );

  return (
    <Link to="home">
      <h1 className="text-white font-serif ">Taxmate</h1>
    </Link>
  );
}
