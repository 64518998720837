import downloadAdminDocument from "../utils/api/AdminDownloadDocument";
import ChangeTicketStatusAdmin from "../utils/api/ChangeTicketStatusAdmin";
import CreatAdmin from "../utils/api/CreateAdmin";
import DeleteAdmin from "../utils/api/DeleteAdmin";
import GetAllAdmins from "../utils/api/GetAllAdmins";
import getFormPricingData from "../utils/api/GetFormPricing";
import getQueryOwner from "../utils/api/GetQueryOwner";
import getRaisedTicketsAdmin from "../utils/api/GetRaisedTicketsAdmin";
import getUserData from "../utils/api/GetUserData";
import InspectUser from "../utils/api/InspectUser";

import { devLogger } from "../utils/logger.config";

export default class AdminHandler {
  static async handleUserData(year: number) {
    try {
      const res = await getUserData(year);

      return res;
    } catch (err: any) {
      devLogger.error(err);
      return err;
    }
  }

  static async handleUserInspect(year: number, id: string) {
    try {
      const res = await InspectUser(year, id);

      return res;
    } catch (err: any) {
      devLogger.error(err);
      return err;
    }
  }

  static async handleGetRaisedTickets(filters: any) {
    try {
      const res = await getRaisedTicketsAdmin(filters);
      return res;
    } catch (err: any) {
      devLogger.error(err);
      throw err;
    }
  }
  static async handleChangeTicketStatus(id: string, data: { [key: string]: any }) {
    try {
      const res = await ChangeTicketStatusAdmin(id, data);
      return res;
    } catch (error) {
      throw error;
    }
  }
  static async handleGetQueryOwner(id: string) {
    try {
      const res = await getQueryOwner(id);
      return res;
    } catch (error) {
      devLogger.error(error);
      throw error;
    }
  }

  static async handleDocumentDownloadAdmin(fileId: string) {
    try {
      const blob = await downloadAdminDocument(fileId); // Pass the token here

      // Create a new Blob URL
      const url = window.URL.createObjectURL(blob);

      // Open the Blob URL in a new tab
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open in new tab
      a.download = ""; // Optional: Set the file name here
      document.body.appendChild(a);
      a.click();
      a.remove(); // Remove the anchor element

      // Optionally revoke the Blob URL after a delay
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100); // Adjust the delay as needed

      // Return any relevant response if needed, else return undefined
    } catch (err: any) {
      throw err; // Propagate the error
    }
  }
  static async handleCreateAdmin(Data: { [key: string]: any }) {
    try {
      const res = await CreatAdmin(Data);
      return res;
    } catch (error) {
      return error;
    }
  }
  static async getAllAdminsList() {
    try {
      const res = await GetAllAdmins();
      return res;
    } catch (error) {
      return error;
    }
  }
  static async handleAdminDelete(id: string) {
    try {
      const res = await DeleteAdmin(id);
      return res;
    } catch (error) {
      return error;
    }
  }
  static async handleFormPricingData() {
    try {
      const res = getFormPricingData();
      return res;
    } catch (error) {
      return error;
    }
  }
}
