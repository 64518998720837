import { Link } from "react-router-dom";

interface NavbarTextProps {
  value: string;
  mobile?: boolean;
  to: string;
}

export default function NavbarText({ value, mobile = false, to }: NavbarTextProps) {
  return (
    <Link
      to={to}
      className={`md:text-white-color font-semibold  text-lg font-sans text-student-tax-primary-theme-color cursor-pointer`}
    >
      {value}
    </Link>
  );
}
