import lang from "../utils/LanguagePacks/en-us.json";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { LuLinkedin } from "react-icons/lu";
import { MdMail } from "react-icons/md";
import { BiPhone } from "react-icons/bi";
function Footer() {
  return (
    <>
      <div className="relative py-12 md:py-20 md:px-52 px-2 bg-student-tax-primary-theme-color">
        <div className="flex md:flex-row justify-between flex-col">
          <div className=" md:w-1/5 flex flex-col  md:mb-0 mb-5">
            <span className="md:mb-0 mb-2 font-semibold font-mono text-white-color md:text-3xl text-xl md:text-left text-center">
              {lang.web_name}
            </span>
            <hr className="border-t-2 border-white-color mb-4 md:block hidden" />
            <span className="text-white-color  text-xs md:text-sm  font-sans font-medium md:text-left text-center">
              {lang.footer_taxmate_desc}
            </span>
            <div className="mt-2 flex flex-row items-center gap-4 justify-center md:justify-start ">
              <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-white-color ">
                <FaFacebook className=" text-student-tax-secondary-theme-color" />
              </div>
              <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-white-color ">
                <LuLinkedin className=" text-student-tax-secondary-theme-color" />
              </div>
              <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-white-color ">
                <FaTwitter className=" text-student-tax-secondary-theme-color" />
              </div>
            </div>
          </div>
          <div className="md:w-1/5 md:mb-0 mb-5">
            <span className="md:mb-0 mb-2 font-semibold font-mono text-white-color md:text-3xl text-xl md:text-left text-center">
              {lang.useful_link}
            </span>
            <hr className="border-t-2 border-white-color mb-4 md:block hidden" />
            <div className="flex flex-col">
              <span className="text-white-color text-xs md:text-sm hover:underline cursor-pointer  font-sans font-medium">
                {lang.home}
              </span>
              <span className="text-white-color text-xs md:text-sm hover:underline cursor-pointer  font-sans font-medium">
                {lang.about_us}
              </span>
              <span className="text-white-color text-xs md:text-sm hover:underline cursor-pointer  font-sans font-medium">
                {lang.history}
              </span>
              <span className="text-white-color text-xs md:text-sm hover:underline cursor-pointer  font-sans font-medium">
                {lang.contact_us}
              </span>
            </div>
          </div>
          <div className=" md:w-1/5">
            <span className="font-semibold font-mono text-white-color md:text-3xl text-xl">
              {lang.contact_us}
            </span>
            <hr className="border-t-2 border-white-color mb-4 md:block hidden" />
            <div className="gap-2 flex flex-col">
              <div className="flex flex-row md:gap-3 gap-1 items-center">
                <div className="border border-student-tax-secondary-theme-color md:h-8 md:w-8 h-6 w-6 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <MdMail className=" text-white-color md:text-lg text-base" />
                </div>
                <span className="text-white-color text-xs md:text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  {process.env.REACT_APP_EMAIL_CONTACT}
                </span>
              </div>
              <div className="flex flex-row md:gap-3 gap-1 items-center">
                <div className="border border-student-tax-secondary-theme-color md:h-8 md:w-8 h-6 w-6 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <BiPhone className=" text-white-color md:text-base text-lg" />
                </div>
                <span className="text-white-color text-xs md:text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  {process.env.REACT_APP_PHONE_CONTACT}
                </span>
              </div>

              <img src=" /Assets/irsAsurance.svg" className="h-10 w-40" alt="ires" />
            </div>
          </div>
          {/* <div className=" w-1/5">
            <span className="font-semibold font-mono text-white-color text-3xl">
              {lang.our_products}
            </span>
            <hr className="border-t-2 border-white-color mb-4" />
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <BiGlobe className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  TaxEve
                </span>
              </div>
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <BsAndroid className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  {lang.web_name}
                </span>
              </div>
              <div className="flex flex-row gap-3 items-center">
                <div className="border border-student-tax-secondary-theme-color h-8 w-8 rounded-full flex justify-center items-center cursor-pointer bg-student-tax-secondary-theme-color ">
                  <FaAppStore className=" text-white-color" />
                </div>
                <span className="text-white-color text-sm text-center font-sans font-medium hover:underline cursor-pointer">
                  {lang.web_name}
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="py-2 md:px-52 px-2 bg-dark-green">
        <div className="flex md:flex-row flex-col items-center justify-between">
          <span className="font-medium text-xs text-white-color">{lang.reserved_rights}</span>
          <div className="flex flex-row gap-1">
            <span className="font-medium text-xs text-white-color hover:underline cursor-pointer hover:text-blue-700">
              {lang.privacy_policy}
            </span>{" "}
            <span className="font-medium text-xs text-white-color">|</span>
            <span className="font-medium text-xs text-white-color hover:underline cursor-pointer hover:text-blue-700">
              {lang.term_condition}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
