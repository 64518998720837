// src/api/getUserData.ts
import axiosInstance from "../axios.config";

export default async function getRaisedTicketsAdmin(filters: any) {
  try {
    const params: Record<string, any> = {};
    if (filters.email) {
      params.email = filters.email;
    }
    if (filters.ticket_status) {
      params.ticket_status = filters.ticket_status;
    }
    const { data } = await axiosInstance.get(`/admin/auth/get_user_raised_tickets`, {
      params,
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
