import { Dispatch } from "@reduxjs/toolkit";
import ToastMessageObj from "../interfaces/ToastMessageObj.interface";
import register from "../utils/api/register";
import { ADMIN_ACCOUNT, ERROR, SS, SUCCESS, USER_ACCOUNT } from "../utils/constants";
import StateSetter, { ScreenDisabler } from "./StateSetter";
import User, { UserData } from "./User";
import emailVerification from "../utils/api/emailVerification";
import LoginData from "../interfaces/LoginData.interface";
import login from "../utils/api/login";
import Token from "./Token";
import Tokens from "../interfaces/Tokens.interface";
import sendVerificationCode from "../utils/api/sendVerificationCode";
import { devLogger } from "../utils/logger.config";
import lang from "../utils/LanguagePacks/en-us.json";
import PasswordResetData from "../interfaces/PasswordResetData.interface";
import resetPassword from "../utils/api/resetPassword";
import ContactUsData from "../interfaces/ContactUsData.interface";
import contactUs from "../utils/api/contactUs";
import updateUsername from "../utils/api/updateUsername";
import otpForEmailUpdate from "../utils/api/otpForEmailUpdate";
import updateEmail from "../utils/api/updateEmail";
import updatePhone from "../utils/api/updatePhonenumber";
import updatePassword from "../utils/api/updatePassword";
import Cache from "./Cache";
import refreshTokens from "../utils/api/refreshTokens";
import Cookie from "./Cookie";
import axiosInstance from "../utils/axios.config";
import getUserData from "../utils/api/GetUserData";
import uploadDocument from "../utils/api/uploadDocument";
import FormUpload from "../utils/api/FormUpload";
import { emailActivation } from "../utils/api/EmailActivation";

import UserHistory from "../utils/api/UserHistory";

import deleteDocument from "../utils/api/DeleteDocument";
import downloadDocument from "../utils/api/DownloadDocument";
import GetCart from "../utils/api/GetCart";
import PaymentNow from "../utils/api/Paynow";
import GetHome from "../utils/api/GetHome";
import updateDocument from "../utils/api/FormUpdate";
import UserTaxDetails from "../utils/api/GetUserTaxDetails";
import raiseQueryFuntion from "../utils/api/RaiseQuery";
import GetPriviouslyRaisedTicket from "../utils/api/GetRaisedtickets";
import AppleLogin from "../utils/api/AppleLogin";

export default class Handler {
  static dispatch: Dispatch<any>;
  static recursionFlag: number = 0;

  static async handleRegistration(userdata: UserData) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      const res = await register(userdata);
      ScreenDisabler.disable(this.dispatch); // Disable screen disabler
      return res;
    } catch (err: any) {
      // Handle error
      this.handleError(err.response, (errstatus) => {
        ScreenDisabler.disable(this.dispatch);
        throw errstatus;
      });
    }
  }

  // document upload

  static async handleDocumentUpload(file: File, taxYear: string, file_type: string) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      const res = await uploadDocument(file, taxYear, file_type); // Pass the token here
      ScreenDisabler.disable(this.dispatch); // Disable screen disabler
      const toastObj: ToastMessageObj = {
        message: res.message,
        type: SUCCESS,
        toDisplay: true,
      };
      StateSetter.setToastMessage(toastObj, this.dispatch);
      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error
        return;
      });
      throw err; // Propagate the error
    }
  }

  //handle details update
  static async handleUpdateUserTaxDetails(updatedForm: { [key: string]: any }) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      // Call the API endpoint to upload user tax details
      const res = await updateDocument(updatedForm); // Pass the entire object
      ScreenDisabler.disable(this.dispatch); // Disable screen disabler

      const toastObj: ToastMessageObj = {
        message: res.message,
        type: SUCCESS,
        toDisplay: true,
      };

      StateSetter.setToastMessage(toastObj, this.dispatch);
      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error

        return;
      });
      throw err; // Propagate the error
    }
  }
  static async handleDocumentDelete(fileId: string) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      const res = await deleteDocument(fileId); // Pass the token here
      ScreenDisabler.disable(this.dispatch); // Disable screen disabler
      const toastObj: ToastMessageObj = {
        message: res.message,
        type: SUCCESS,
        toDisplay: true,
      };
      StateSetter.setToastMessage(toastObj, this.dispatch);
      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error
        return;
      });
      throw err; // Propagate the error
    }
  }

  // download document

  static async handleDocumentDownload(fileId: string) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      const blob = await downloadDocument(fileId); // Pass the token here

      // Create a new Blob URL
      const url = window.URL.createObjectURL(blob);

      // Open the Blob URL in a new tab
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open in new tab
      a.download = ""; // Optional: Set the file name here
      document.body.appendChild(a);
      a.click();
      a.remove(); // Remove the anchor element

      // Optionally revoke the Blob URL after a delay
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100); // Adjust the delay as needed

      ScreenDisabler.disable(this.dispatch); // Disable screen disabler
      const toastObj: ToastMessageObj = {
        message: "Downloaded Successfully",
        type: SUCCESS,
        toDisplay: true,
      };
      StateSetter.setToastMessage(toastObj, this.dispatch);
      // Return any relevant response if needed, else return undefined
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error
        return;
      });
      throw err; // Propagate the error
    }
  }

  //Get user history data
  static async handleUserHistory(year: number) {
    try {
      const data: any = await UserHistory(year);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  //Get user tax details
  static async getUserTaxDetails(year: number) {
    try {
      const data: any = await UserTaxDetails(year);

      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
  // Get Cart Data
  static async handleUserCart(year: number) {
    try {
      const data: any = await GetCart(year);

      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  static async handleAppleLogin(logindata: any) {
    try {
      const data: any = await AppleLogin(logindata);
      console.log(data, "Data in helper funtion ");
      return data;
    } catch (error) {
      console.log(error, "This is eror in heloper funtuon ");
      throw error;
    }
  }
  //Get the user submitted the data or not
  static async handleUserHome(year: number) {
    try {
      const data: any = await GetHome(year);
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
  // email activation
  static async handleActivationEmail(token: string) {
    // try {
    //   const data = await emailActivation(token);
    //   // return { success: true, message: data.message || "Activation successful!" };
    //   return data
    // } catch (error:any) {
    //   return { success: false, message: error.message };
    // }

    try {
      const data: any = await emailActivation(token);
      //  return { success: true, message: data.message || "Activation successful!" }; // Return success data
      return data;
    } catch (error: any) {
      return { success: false, message: error.message }; // Return the error message
    }
  }

  // Form Upload Handler
  static async handleFormUpload(formData: { [key: string]: any }) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      // Call the API endpoint to upload user tax details
      const res = await FormUpload(formData); // Pass the entire object
      ScreenDisabler.disable(this.dispatch); // Disable screen disabler

      const toastObj: ToastMessageObj = {
        message: res.message,
        type: SUCCESS,
        toDisplay: true,
      };

      StateSetter.setToastMessage(toastObj, this.dispatch);
      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error

        return;
      });
      throw err; // Propagate the error
    }
  }

  //Raise Ticket Handler
  static async raiseQuery(query: { [key: string]: string }) {
    try {
      const res = await raiseQueryFuntion(query);

      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error

        return;
      });
      throw err;
    }
  }
  // Get Peiviously Raised Tickets
  static async getPrioviouslyRaisedTickets() {
    try {
      const res = await GetPriviouslyRaisedTicket();
      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error

        return;
      });
      throw err;
    }
  }
  // Payment Handler
  static async handlePayment(formData: { [key: string]: any }) {
    ScreenDisabler.enable(this.dispatch); // Enable screen disabler
    try {
      // Call the API endpoint to upload user tax details
      const res = await PaymentNow(formData); // Pass the entire object
      ScreenDisabler.disable(this.dispatch); // Disable screen disabler

      const toastObj: ToastMessageObj = {
        message: res.Message,
        type: SUCCESS,
        toDisplay: true,
      };

      StateSetter.setToastMessage(toastObj, this.dispatch);
      return res;
    } catch (err: any) {
      this.handleError(err.response, (errStatus) => {
        ScreenDisabler.disable(this.dispatch); // Disable on error

        return;
      });
      throw err; // Propagate the error
    }
  }

  static async handleEmailVerification(email: string) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const res = await emailVerification(email);
      ScreenDisabler.disable(this.dispatch);
      // Show a toast message
      const toastObj: ToastMessageObj = {
        message: res.message,
        type: SUCCESS,
        toDisplay: true,
      };
      StateSetter.setToastMessage(toastObj, this.dispatch);
    } catch (err: any) {
      this.handleError(err.response, (status) => {
        ScreenDisabler.disable(this.dispatch);
        return;
      });
    }
  }

  static async handleLogin(loginData: LoginData) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const data: Tokens = await login(loginData);
      // Save the tokens
      devLogger.log("Saving the access tokens...");
      Token.saveAccessToken("auth_token", data.access_token);
      Token.saveRefreshToken("refresh_token", data.refresh_token);
      devLogger.log("Access tokens saved!");

      // Cache the user details
      devLogger.log("Saving user details...");
      const sscache = new Cache(SS);
      sscache.save("user_detail", JSON.stringify(data.userInfo));
      devLogger.log("User details saved!");

      // Login user
      devLogger.log("Attempting user login...");
      User.authorize(this.dispatch);

      ScreenDisabler.disable(this.dispatch);
    } catch (err: any) {
      this.handleError(err.response, (errstatus: number) => {
        ScreenDisabler.disable(this.dispatch);
        throw errstatus;
      });
    }
  }

  static async handleSendingVerificationCode(email: string, accountType: string) {
    ScreenDisabler.enable(this.dispatch);
    try {
      let res;

      switch (accountType) {
        case USER_ACCOUNT:
          res = await sendVerificationCode(email);
          break;

        case ADMIN_ACCOUNT:
          res = await axiosInstance.post("/admin/send_verification_code", { email });
          break;

        default:
          throw new Error("Account type not supported yet!");
      }
      devLogger.log(res);
      ScreenDisabler.disable(this.dispatch);
      // Display a toast message
      const toastMessageObj: ToastMessageObj = {
        message: lang.verification_code_sent,
        type: SUCCESS,
        toDisplay: true,
      };
      StateSetter.setToastMessage(toastMessageObj, this.dispatch);
    } catch (err: any) {
      this.handleError(err.response, (errstatus) => {
        ScreenDisabler.disable(this.dispatch);
        throw errstatus;
      });
    }
  }

  // Handle password reset
  static async handlePasswordReset(passResetData: PasswordResetData, accountType: string) {
    ScreenDisabler.enable(this.dispatch);
    try {
      let res;

      switch (accountType) {
        case USER_ACCOUNT:
          res = await resetPassword(passResetData);
          break;

        case ADMIN_ACCOUNT:
          res = await axiosInstance.put("/admin/reset_password", passResetData);
          break;

        default:
          throw new Error("Account type not supported yet!");
      }

      devLogger.log(res);
      ScreenDisabler.disable(this.dispatch);

      // Display a toast message
      const toastMessageObj: ToastMessageObj = {
        message: lang.password_reset_success,
        type: SUCCESS,
        toDisplay: true,
      };
      StateSetter.setToastMessage(toastMessageObj, this.dispatch);
    } catch (err: any) {
      this.handleError(err.response, (errstatus) => {
        ScreenDisabler.disable(this.dispatch);
        throw errstatus;
      });
    }
  }

  static async handleContactUsFormSubmission(data: ContactUsData) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const res = await contactUs(data);
      ScreenDisabler.disable(this.dispatch);

      devLogger.log("Response after submitting the contact us form");
      devLogger.log(res);

      // Display a toast message
      StateSetter.setToastMessage(
        {
          message: lang.contact_us_form_submitted,
          type: SUCCESS,
          toDisplay: true,
        },
        this.dispatch
      );
    } catch (err: any) {
      this.handleError(err.response, (errstatus) => {
        ScreenDisabler.disable(this.dispatch);
        throw errstatus;
      });
    }
  }

  // Handle username update
  public static async handleUsernameUpdate(newname: string) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const data = await updateUsername(newname);
      ScreenDisabler.disable(this.dispatch);

      devLogger.log(data);

      // Display a success toast message
      StateSetter.setToastMessage(
        {
          message: data.message,
          type: SUCCESS,
          toDisplay: true,
        },
        this.dispatch
      );

      // Reset the recursion flag
      if (this.recursionFlag > 0) this.recursionFlag = 0;
    } catch (err: any) {
      this.handleError(err.response, (errstatus, recursionLimitReached) => {
        ScreenDisabler.disable(this.dispatch);
        if (errstatus === 401 && !recursionLimitReached) {
          this.handleUsernameUpdate(newname);
        }
      });
    }
  }

  // Handle sending verification code for email update
  public static async handleSendingOTPForEmailUpdate({
    password,
    new_email,
  }: {
    password: string;
    new_email: string;
  }) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const data = await otpForEmailUpdate({ password, new_email });
      ScreenDisabler.disable(this.dispatch);

      devLogger.log(data);

      // Display a success toast message
      StateSetter.setToastMessage(
        {
          message: data.message,
          type: SUCCESS,
          toDisplay: true,
        },
        this.dispatch
      );
      // Reset the recursion flag
      if (this.recursionFlag > 0) this.recursionFlag = 0;
    } catch (err: any) {
      this.handleError(err.response, (errstatus, recursionLimitReached) => {
        ScreenDisabler.disable(this.dispatch);
        if (errstatus === 401 && !recursionLimitReached) {
          this.handleSendingOTPForEmailUpdate({ password, new_email });
        }
      });
    }
  }

  // Handle email update
  public static async handleEmailUpdate({
    new_email,
    verification_code,
  }: {
    new_email: string;
    verification_code: string;
  }) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const data = await updateEmail({ new_email, verification_code });
      ScreenDisabler.disable(this.dispatch);

      devLogger.log(data);

      StateSetter.setToastMessage(
        {
          message: data.message,
          type: SUCCESS,
          toDisplay: true,
        },
        this.dispatch
      );
      // Reset the recursion flag
      if (this.recursionFlag > 0) this.recursionFlag = 0;
    } catch (err: any) {
      this.handleError(err.response, (errstatus, recursionLimitReached) => {
        ScreenDisabler.disable(this.dispatch);
        if (errstatus === 401 && !recursionLimitReached) {
          this.handleEmailUpdate({
            new_email,
            verification_code,
          });
        }
      });
    }
  }

  // Handle phone number update
  public static async handlePhonenumberUpdate({
    password,
    phone_no,
  }: {
    password: string;
    phone_no: number;
  }) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const data = await updatePhone({ password, phone_no });
      ScreenDisabler.disable(this.dispatch);

      devLogger.log(data);

      StateSetter.setToastMessage(
        {
          message: data.message,
          type: SUCCESS,
          toDisplay: true,
        },
        this.dispatch
      );
      // Reset the recursion flag
      if (this.recursionFlag > 0) this.recursionFlag = 0;
    } catch (err: any) {
      this.handleError(err.response, (errstatus, recursionLimitReached) => {
        ScreenDisabler.disable(this.dispatch);
        if (errstatus === 401 && !recursionLimitReached) {
          this.handlePhonenumberUpdate({
            password,
            phone_no,
          });
        }
      });
    }
  }

  // Handle password update
  public static async handlePasswordUpdate({
    old_password,
    new_password,
  }: {
    old_password: string;
    new_password: string;
  }) {
    ScreenDisabler.enable(this.dispatch);
    try {
      const data = await updatePassword({ old_password, new_password });
      ScreenDisabler.disable(this.dispatch);
      devLogger.log(data);

      StateSetter.setToastMessage(
        {
          message: data.message,
          type: SUCCESS,
          toDisplay: true,
        },
        this.dispatch
      );
      // Reset the recursion flag
      if (this.recursionFlag > 0) this.recursionFlag = 0;
    } catch (err: any) {
      this.handleError(err.response, (errstatus, recursionLimitReached) => {
        ScreenDisabler.disable(this.dispatch);
        if (errstatus === 401 && !recursionLimitReached) {
          this.handlePasswordUpdate({
            old_password,
            new_password,
          });
        }
      });
    }
  }

  public static handleError(
    errorRes: any,
    callback: (errstatus: number, recursionLimitReached?: boolean) => void
  ) {
    devLogger.error(errorRes);
    const errorStatus = errorRes.status;
    switch (errorStatus) {
      case 400:
      case 500:
        // Show error toast message

        const toastObj: ToastMessageObj = {
          message: errorRes.data.Error,
          type: ERROR,
          toDisplay: true,
        };
        StateSetter.setToastMessage(toastObj, this.dispatch);
        callback(errorStatus);
        break;

      case 403:
        callback(errorStatus);
        break;

      case 401:
        try {
          devLogger.error("This is an unauthorized request!");
          devLogger.error("Incrementing the flag by 1...");
          ++this.recursionFlag;
          devLogger.error("Current flag value: " + this.recursionFlag);

          if (this.recursionFlag <= 3) {
            devLogger.error("Calling the refresh token API");

            const existingTokens = {
              access_token: Cookie.retrieveCookie("auth_token") ?? "",
              refresh_token: new Cache(SS).get("refresh_token") ?? "",
            };

            devLogger.log("Existing Tokens: ");
            devLogger.log(existingTokens);

            refreshTokens(existingTokens)
              .then((res: { access_token: string; refresh_token: string }) => {
                // Cache the new sets of access and refresh token
                devLogger.log("Saving the new sets of tokens...");
                Cookie.setCookie("auth_token", res.access_token);
                new Cache(SS).save("refresh_token", res.refresh_token);
                devLogger.log("Tokens saved!");

                callback(errorStatus, false);
              })
              .catch((err) => {
                throw err;
              });
          } else {
            throw new Error("rcle").message;
          }
        } catch (err) {
          devLogger.error(err);
          err === "rcle"
            ? devLogger.error("Recursion limit exceeded!")
            : devLogger.error("SOME ERROR OCCURED!");
          devLogger.error("Resetting the recursion flag...");
          this.recursionFlag = 0;

          devLogger.error("Logging out user!");
          User.logout(this.dispatch);

          callback(errorStatus, true);
        }
        break;

      default:
        break;
    }
  }
}
