import React, { useEffect, useState } from "react";
import YearDropdown from "../YearDropdown";
import UserDetail from "../UserDetail";
import Handler from "../../classes/Handler";
import lang from "../../utils/LanguagePacks/en-us.json";
import User from "../../interfaces/UserData.interface";
import Loading from "../Decorations/Loading";
import { devLogger } from "../../utils/logger.config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HistoryPageProps from "../../interfaces/HistoryPage.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { logEvent } from "../../googleAnalytics/ga";
import { actions, category } from "../../utils/CustomAnalytics";

function HistoryPage({ q, ty }: HistoryPageProps) {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the 'year' query parameter (if available)
  const searchParams = new URLSearchParams(location.search);
  const queryYear = searchParams.get("year");
  const currentYear = new Date().getFullYear(); //current year
  const [selectedYear, setSelectedYear] = useState(queryYear ? +queryYear : currentYear - 1); // store selected Year
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userData, setUserData] = useState<any[]>([]);
  const [fileUploads, setFileUploads] = useState<any[]>([]);
  const [paidStatus, setpaidStatus] = useState(false);
  useEffect(() => {
    const loadTaxDetails = async () => {
      setFileUploads([]);
      setError(null);
      setLoading(true);
      setpaidStatus(false);
      setUserData([]);
      try {
        const response = await Handler.handleUserHistory(selectedYear);
        if (response?.data?.paid === false) {
          setpaidStatus(response.data.paid);
        } else {
          setpaidStatus(true);
          const filteredData = filterUserData(response?.data);
          setUserData(filteredData);
        }
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadTaxDetails();
  }, [selectedYear]);

  useEffect(() => {
    if (q === "success" && ty) {
      toast.success(lang.payment_success);
      logEvent(category.payment, actions.payment_success);
      const timer = setTimeout(() => {
        loadTaxDetailsWithDelay();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [q, ty]);
  const loadTaxDetailsWithDelay = async () => {
    if (!ty) {
      return;
    }
    handleYearChange(+ty);
    setFileUploads([]);
    setLoading(true);
    setpaidStatus(false);
    setUserData([]);
    setError(null);

    try {
      const response = await Handler.handleUserHistory(selectedYear);
      if (response?.data?.paid === false) {
        setpaidStatus(response.data.paid);
      } else {
        setpaidStatus(true);
        const filteredData = filterUserData(response?.data);
        setUserData(filteredData);
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  //Year UPDATE FUNCTION
  const handleYearChange = (year: number) => {
    setSelectedYear(year); // Update state with the selected year
    setUserData([]);
    setFileUploads([]);
  };

  //   Here filtering the data  take those data whose value is present and remove those key whose value is not present

  const filterUserData = (data: User) => {
    const filteredEntries = Object.entries(data).filter(([key, value]) => {
      // Ignore file_ids key
      if (key === "file_ids" && Array.isArray(value)) {
        setFileUploads(value); // Store the array of files in state
        return false; // Exclude file_ids from filtered data
      }

      // Continue filtering other keys
      return typeof value !== "string" || value.trim() !== "";
    });

    const formattedData = filteredEntries.map(([key, value]) => {
      // Transform boolean values to "Yes" or "No"
      if (typeof value === "boolean") {
        return {
          label: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
          value: value ? "Yes" : "No",
        };
      }

      // Format date_of_birth
      if (key === "date_of_birth" && typeof value === "string") {
        return {
          label: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
          value: formatDate(value),
        };
      }

      // Format createdAt
      if (key === "createdAt" && typeof value === "string") {
        return {
          label: "Submitted On",
          value: new Date(value).toLocaleString(), // Convert to local time and date
        };
      }
      if (key === "mailing_add_line1" && typeof value === "string") {
        return {
          label: "Adddress Line 1",
          value: value, // Convert to local time and date
        };
      }
      if (key === "mailing_add_line2" && typeof value === "string") {
        return {
          label: "Adddress Line 2",
          value: value, // Convert to local time and date
        };
      }
      if (key === "mailing_add_city" && typeof value === "string") {
        return {
          label: "City",
          value: value, // Convert to local time and date
        };
      }
      if (key === "mailing_add_state" && typeof value === "string") {
        return {
          label: "State",
          value: value, // Convert to local time and date
        };
      }
      if (key === "mailing_add_zip" && typeof value === "string") {
        return {
          label: "Zip",
          value: value, // Convert to local time and date
        };
      }
      if (key === "mailing_add_country" && typeof value === "string") {
        return {
          label: "Country",
          value: value, // Convert to local time and date
        };
      }

      // Return other values as they are
      return {
        label: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
        value: value,
      };
    });

    return chunkArray(formattedData, 2); // Assuming you are chunking the array into pairs for display
  };
  const formatDate = (dateStr: string) => {
    if (!dateStr || dateStr.length !== 8) return dateStr; // Return original if invalid

    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);

    return `${month}-${day}-${year}`; // MM-DD-YYYY format
  };

  const chunkArray = (array: any[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };
  //api call for dowmload document

  const handleDownload = async (id: string) => {
    setLoading(true);
    try {
      await Handler.handleDocumentDownload(id); // Call the handler function
    } catch (error) {
      devLogger.error(error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    <Loading />;
  }

  return (
    <div className="max-w-7xl mb-1 mx-auto p-6 shadow-[8px_8px_15px_rgba(0,0,0,0.3),-8px_-8px_15px_rgba(255,255,255,0.8)]">
      <YearDropdown
        currentYear={currentYear}
        onYearChange={setSelectedYear}
        selectedYear={selectedYear}
      />
      {!paidStatus && !error && (
        <div className=" flex justify-center mt-8 items-center flex-col">
          <span className="font-bold text-red-700 text-xl">
            {lang.tax_details_submitted_payment_not_done}
          </span>
          <button
            className="btn btn-primary mt-2 py-2 px-4"
            onClick={() => navigate(`/cart?year=${selectedYear}`)} // Assuming you are using react-router-dom for navigation
          >
            Cart
          </button>
        </div>
      )}
      {userData.length > 0 && paidStatus && (
        <>
          {userData && (
            <div>
              {userData.map((detailPair, index) => (
                <div className="flex flex-wrap justify-between my-4">
                  {detailPair.map((detail: any) => (
                    <div className="w-full md:w-1/2 md:px-2">
                      <UserDetail label={detail.label} value={detail.value} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          <span className="font-bold text-lg text-light-black dark:text-gray-300">
            Document Uploaded
          </span>

          {fileUploads && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 my-4">
                {fileUploads.map((data) => (
                  <div className="flex items-center space-x-2">
                    <UserDetail
                      label={data.file_type}
                      value={data.filename_key}
                      id={data._id}
                      onClick={handleDownload}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}

      {error && (
        <div className=" flex justify-center mt-8">
          <span className="font-bold text-red-700 text-xl">{error}</span>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default HistoryPage;
