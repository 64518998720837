// const DocumentList = [
//   { label: "W2Form", value: "w2" },
//   { label: "Form1099", value: "1099" },
//   { label: "AnyOtherIncomeForm", value: "other_income" },
//   { label: "Tuition Statement", value: "1098t" },
//   { label: "Passport", value: "passport" },
//   { label: "USEntryExitDates", value: "us_entry_exit" },
//   { label: "AllIncomeForm", value: "other_income" },
//   { label: "SSN", value: "tin" },
//   { label: "visa", value: "immi_status" },
// ];
const DocumentList = [
  { name: "W2 Form", code: "w2" },
  { name: "1099 Form", code: "1099" },
  { name: "Tuition Statement (1098-T)", code: "1098t" },
  { name: "1042-S Form", code: "1042s" },
  { name: "Any Other Income Details", code: "other_income" },
  { name: "SSN / ITIN", code: "tin" },
  { name: "Passport (For Non-Residents Only)", code: "passport" },
  { name: "visa (For Non-Residents Only)", code: "immi_status" },
  { name: "US Entry and Exit Dates (For Non-Residents Only)", code: "us_entry_exit" },
];

export default DocumentList;
