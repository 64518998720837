import React, { useState } from "react";
import Formatter from "../classes/Formatter";
import ContactUsForm from "../components/InfoRelated/ContactUs/ContactUsForm";
import ContactUsData from "../interfaces/ContactUsData.interface";
import { EMAIL, NAME, PHONE, QUERY } from "../utils/constants";
import Validator from "../classes/Validator";
import Handler from "../classes/Handler";
import { devLogger } from "../utils/logger.config";
import lang from "../utils/LanguagePacks/en-us.json";
import { LuCopyCheck, LuMail, LuPhone } from "react-icons/lu";
import { FiCopy } from "react-icons/fi";
import Footer from "../components/Footer";

export default function ContactUs() {
  const [contactusData, setContactusData] = useState<ContactUsData>({
    name: "",
    email: "",
    query: "",
    phone: 0,
  });
  const subject = "Contact TaxMate";
  const [showIndicator, toShowIndicator] = useState({
    onEmail: false,
    onName: false,
    onQuery: false,
  });
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedPhone, setIsCopiedPhone] = useState(false);
  // Handle chane event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id: type, value } = e.currentTarget;
    let isValidLength: boolean;

    switch (type) {
      case NAME:
        // Remove any pre displayed errors
        toShowIndicator({
          ...showIndicator,
          onName: false,
        });

        const namemaxlimit = process.env.REACT_APP_NAME_MAX_LIMIT || "50";

        isValidLength = value.length <= parseInt(namemaxlimit);
        isValidLength &&
          setContactusData({
            ...contactusData,
            name: value,
          });
        break;

      case EMAIL:
        // Remove any pre displayed errors
        toShowIndicator({
          ...showIndicator,
          onEmail: false,
        });

        const emailmaxlimit = process.env.REACT_APP_EMAIL_MAX_LIMIT || "30";

        isValidLength = value.length <= parseInt(emailmaxlimit);
        isValidLength &&
          setContactusData({
            ...contactusData,
            email: value,
          });
        break;

      case PHONE:
        const isValid = Validator.validateNumberOnly(Formatter.formatPhone(0, value));
        isValidLength = Formatter.formatPhone(0, value).length <= 10;
        isValid &&
          isValidLength &&
          setContactusData({
            ...contactusData,
            phone: parseInt(Formatter.formatPhone(0, value)),
          });
        break;

      case QUERY:
        // Remove any pre displayed errors
        toShowIndicator({
          ...showIndicator,
          onQuery: false,
        });

        const querymaxlimit = process.env.REACT_APP_QUERY_MAX_LIMIT || "500";

        isValidLength = value.length <= parseInt(querymaxlimit);
        isValidLength &&
          setContactusData({
            ...contactusData,
            query: value,
          });
        break;

      default:
        break;
    }
  };

  // Handle submit event
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate email and check if required fields are present
    const isValidEmail = Validator.validateEmail(contactusData.email);
    const namePresent = contactusData.name.length > 0;
    const queryPresent = contactusData.query.length > 0;

    if (!isValidEmail && !namePresent && !queryPresent) {
      toShowIndicator({
        onEmail: true,
        onName: true,
        onQuery: true,
      });
      return;
    } else if (!isValidEmail) {
      toShowIndicator({
        ...showIndicator,
        onEmail: true,
      });
      return;
    } else if (!namePresent) {
      toShowIndicator({
        ...showIndicator,
        onName: true,
      });
      return;
    } else if (!queryPresent) {
      toShowIndicator({
        ...showIndicator,
        onQuery: true,
      });
      return;
    }

    // Handle form submission
    try {
      await Handler.handleContactUsFormSubmission(contactusData);
      // Clear the input fields
      setContactusData({
        name: "",
        email: "",
        query: "",
        phone: 0,
      });
    } catch (errstatus) {
      devLogger.error(errstatus);
    }
  };
  const handleCopy = (reason: string) => {
    if (reason === "email") {
      navigator.clipboard
        .writeText(process.env.REACT_APP_EMAIL_CONTACT || "conctact@taxmate.com")
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000); // Reset `isCopied` after 2 seconds
        });
    } else {
      navigator.clipboard.writeText(process.env.REACT_APP_PHONE_CONTACT || "123654789").then(() => {
        setIsCopiedPhone(true);
        setTimeout(() => setIsCopiedPhone(false), 2000); // Reset `isCopied` after 2 seconds
      });
    }
  };
  return (
    <>
      <div className="mt-12 md:px-52 px-2 mb-2">
        <div className="w-full flex  justify-center">
          <div className="flex  flex-row gap-2 md:mb-16 mb-4">
            <hr className="border-t-2 border-student-tax-secondary-theme-color md:w-44 md:my-6" />

            <h2 className="md:text-5xl text-2xl font-semibold text-student-tax-primary-theme-color font-mono text-center">
              {lang.get_in_touch}
            </h2>
            <hr className="border-t-2 border-student-tax-secondary-theme-color  md:w-44 md:my-6 text-center" />
          </div>
        </div>
        <div className="flex md:flex-row justify-between w-full flex-col md:gap-0 gap-3">
          <div className="md:w-2/5">
            <div className="bg-creame-color border border-student-tax-secondary-theme-color rounded-lg rounded-tr-3xl p-5 flex flex-col gap-3">
              <div className="border border-navyblue bg-input-bg rounded-lg flex flex-row justify-between items-center pr-5">
                <div className="flex flex-row items-center p-3 gap-2">
                  <div className="h-8 w-8 flex items-center justify-center rounded-full border border-navy-blue">
                    <LuMail className="text-navyblue font-semibold" />
                  </div>
                  <a
                    href={`mailto:${
                      process.env.REACT_APP_EMAIL_CONTACT
                    }?subject=${encodeURIComponent(subject)}`}
                    className="text-navyblue font-semibold hover:underline"
                  >
                    {process.env.REACT_APP_EMAIL_CONTACT}
                  </a>
                </div>

                {isCopied ? (
                  <LuCopyCheck className="text-navyblue font-semibold" />
                ) : (
                  <FiCopy
                    onClick={() => handleCopy("email")}
                    className="text-navyblue font-semibold"
                  />
                )}
              </div>
              <div className="border border-navyblue bg-input-bg rounded-lg flex flex-row justify-between items-center pr-5">
                <div className="flex flex-row items-center p-3 gap-2">
                  <div className="h-8 w-8 flex items-center justify-center rounded-full border border-navy-blue">
                    <LuPhone className="text-navyblue font-semibold" />
                  </div>

                  <a
                    href={`tel:${process.env.REACT_APP_PHONE_CONTACT}`}
                    className="text-navyblue font-semibold hover:underline"
                  >
                    {process.env.REACT_APP_PHONE_CONTACT}
                  </a>
                </div>
                {isCopiedPhone ? (
                  <LuCopyCheck className="text-navyblue font-semibold" />
                ) : (
                  <FiCopy
                    onClick={() => handleCopy("phone")}
                    className="text-navyblue font-semibold"
                  />
                )}
              </div>
            </div>
          </div>

          <ContactUsForm
            onChange={handleChange}
            onSubmit={handleSubmit}
            contactusData={contactusData}
            showIndicator={showIndicator}
          />
        </div>
      </div>
      <Footer/>
    </>
  );
}
