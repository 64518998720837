import React, { useState, useRef, useEffect } from "react";
import { BiUpload } from "react-icons/bi";
import { FiFile } from "react-icons/fi";
import { TbTrash } from "react-icons/tb";
import Handler from "../classes/Handler";
import { devLogger } from "../utils/logger.config";
import DocumentList from "../utils/DocumentArray";
import Modal from "./Modal";
import lang from "../utils/LanguagePacks/en-us.json";
import formatFileName from "../utils/FormatFileName";
import Dropdown from "./Dropdown";

interface UploadedFile {
  key: string;
  file: File;
  name: string;
  type: string;
  size: number;
  lastModified: number;
  id: string;
}

interface Document {
  taxyear: number;
  documentIds: string[];
  addDocumentId: (id: string) => void;
  removeDocumentId: (id: string) => void;
  disabled?: boolean;
  existingFiles?: Array<{ _id: string; file_type: string; filename_key: string }>;
}

const DocumentUploadComponent = ({
  taxyear,
  documentIds,
  addDocumentId,
  removeDocumentId,
  disabled = false,
  existingFiles = [],
}: Document) => {
  const MAX_TOTAL_DOCUMENTS = 15;
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const BLOCKED_EXTENSIONS = [".dmg", ".apk", ".exe", ".js", ".jsx", ".ts", ".tsx", ".scr", ".vbs", ".xml", ".docm", ".xps", ".iso", ".img"];

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  //for document delete warning
  const [documentDeleteWarning, setdocumentDeleteWarning] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (existingFiles.length > 0) {
      const mappedFiles = existingFiles.map((file) => ({
        key: file._id, // Using _id as the unique key
        file: {} as File, // We don't have the actual file content, so leave it as an empty object
        name: formatFileName(file.filename_key), // We can use filename_key as the file name
        type: file.file_type, // File type (w2, 1099, etc.)
        size: 0, // No size data from the API, so setting it to 0
        lastModified: 0, // No last modified data, so setting it to 0
        id: file._id, // File ID from the API response
      }));
      setUploadedFiles(mappedFiles);
    }
  }, [existingFiles]);

  const validateFile = (file: File): boolean => {
    const fileExtension = "." + file.name.split(".").pop()?.toLowerCase();

    if (BLOCKED_EXTENSIONS.includes(fileExtension || "")) {
      setError(`File type ${fileExtension} is not allowed`);
      setIsModalOpen(true);
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      setError(lang.max_file_size);
      setIsModalOpen(true);
      return false;
    }

    if (uploadedFiles.length >= MAX_TOTAL_DOCUMENTS) {
      setError(lang.max_file_upload);
      setIsModalOpen(true);
      return false;
    }

    return true;
  };

  const handleFileUpload = async (files: File[]) => {
    if (!selectedDocumentType) {
      setError(lang.document_select_warning);
      setIsModalOpen(true);
      return;
    }

    const validFiles = files.filter(validateFile).map((file) => ({
      key: Math.random().toString(36).substr(2, 9),
      file,
      name: file.name,
      type: selectedDocumentType,
      size: file.size,
      lastModified: file.lastModified,
    }));

    if (validFiles.length > 0) {
      try {
        const res = await Handler.handleDocumentUpload(
          validFiles[0].file,
          taxyear.toString(),
          validFiles[0].type
        );

        const updatedFile = {
          ...validFiles[0],
          id: res?.file_id,
        };
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setUploadedFiles((prev) => [...prev, updatedFile]);
        addDocumentId(res?.file_id);
        setSelectedDocumentType("");
      } catch (error) {
        devLogger.error(error);
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!selectedDocumentType) {
      setError(lang.document_select_warning);
      setIsModalOpen(true);
      return;
    }

    const files = Array.from(e.dataTransfer.files);
    handleFileUpload(files);
  };

  const removeFile = async (id: string, size: number) => {
    if (disabled) {
      return;
    }
    setDeletingId(id);
    if (size === 0) {
      handleOpenModalDocumentDeleteWarning();
    } else {
      await PerformFileDelete(id);
    }
  };
  const PerformFileDelete = async (id: string) => {
    try {
      const res = await Handler.handleDocumentDelete(id);
      if (res) {
        setUploadedFiles((prev) => prev.filter((file) => file.id !== id));
        removeDocumentId(id);
      }
    } catch (error) {
      devLogger.error(error);
    }
  };

  const triggerFileInput = (e: any) => {
    e.preventDefault();
    if (!selectedDocumentType) {
      setError("Please select a document type first");
      setIsModalOpen(true);
      return;
    }
    fileInputRef.current?.click();
  };
  const handleDownloadDocument = async (id: string) => {
    try {
      await Handler.handleDocumentDownload(id); // Call the handler function
    } catch (error) {
      devLogger.error(error);
    } finally {
    }
  };

  const handleOpenModalDocumentDeleteWarning = () => {
    setdocumentDeleteWarning(true);
  };
  const handleCloseModalDocumentDeleteWarning = () => {
    setdocumentDeleteWarning(false);
  };
  const handleAgreeToDelete = async () => {
    if (deletingId) {
      handleCloseModalDocumentDeleteWarning();
      await PerformFileDelete(deletingId);
      setDeletingId(""); // Clear the state after deletion
    }
  };
  const handleSelectionCountry = (selectedOption: { name: string; code: string }) => {
    setSelectedDocumentType(selectedOption?.code);
  };
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">{lang.document_upload}</h2>

      <div className="mb-4 w-1/4">
        <label htmlFor="document-type" className="block text-sm font-medium text-gray-700 mb-2">
          {lang.select_document}
        </label>

        <Dropdown
          options={DocumentList}
          label=""
          onChange={handleSelectionCountry}
          disabled={disabled}
          value={selectedDocumentType}
          required={false}
          codeShow={false}
          removeValue={true}
        />
      </div>

      <div
        className={`border-2 border-dashed border-gray-300 p-6 text-center rounded-lg mb-4 shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] cursor-pointer ${
          disabled ? "bg-gray-300" : "hover:border-blue-500 transition"
        }`}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => handleFileUpload(Array.from(e.target.files || []))}
          multiple
          className="hidden"
          disabled={disabled}
        />
        <div className="flex flex-col items-center">
          <BiUpload className="w-12 h-12  mb-4" />
          <p className=" mb-2">{lang.drag_and_drop}</p>
          <button
            onClick={triggerFileInput}
            className={`${
              disabled ? "bg-gray-700" : "bg-student-tax-secondary-theme-color"
            } px-4 py-2  text-white rounded-md  transition`}
          >
            {lang.browse_file}
          </button>
        </div>
      </div>

      {uploadedFiles.length > 0 && (
        <div className="mt-4">
          <h4 className="text-sm font-medium mb-2">{lang.upload}</h4>
          <ul className="space-y-2 mb-3">
            {uploadedFiles.map((uploadedFile) => (
              <li className="flex items-center justify-between bg-gray-100 dark:bg-slate-600 p-2 rounded-md">
                <div className="flex items-center">
                  <FiFile className="w-5 h-5 mr-2 text-blue-500" />
                  <div>
                    <span
                      onClick={() => handleDownloadDocument(uploadedFile?.id)}
                      className="text-sm underline text-taxeve-primary-blue cursor-pointer font-bold"
                    >
                      {uploadedFile.name}
                    </span>
                    <span className="ml-2 text-xs  font-bold">
                      {DocumentList.find((type) => type.code === uploadedFile.type)?.name}
                    </span>
                    {uploadedFile.size !== 0 && (
                      <span className="ml-2 text-xs font-bold">
                        ({(uploadedFile.size / 1024).toFixed(1)} KB)
                      </span>
                    )}
                  </div>
                </div>
                <button
                  onClick={(e: any) => {
                    e.preventDefault();
                    removeFile(uploadedFile.id, uploadedFile.size);
                  }}
                  className={`${disabled ? "text-grey-500" : "text-red-500 hover:text-red-700"} `}
                >
                  <TbTrash className="w-5 h-5" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={lang.error}
        content={error}
        buttonText={lang.ok}
        type="error"
      />
      <Modal
        isOpen={documentDeleteWarning}
        onClose={handleCloseModalDocumentDeleteWarning}
        title={lang.warning}
        content={lang.document_delete_warning}
        type="warning"
        buttonText={lang.ok}
        isCancel={true}
        onAgree={handleAgreeToDelete}
      />
    </div>
  );
};

export default DocumentUploadComponent;
