import axiosInstance from "../axios.config";

export default async function GetPriviouslyRaisedTicket() {
  try {
    const { data } = await axiosInstance.get(`/get_raised_tickets`);
    return data;
  } catch (err: any) {
    throw err;
  }
}
