import React, { useState, useContext } from "react";
import { NEW_USERNAME } from "../../../utils/constants";
import Middleware from "../../../classes/Middleware";
import Handler from "../../../classes/Handler";
import { prodLogger } from "../../../utils/logger.config";
import { UserDetailContext } from "../../../pages/ProfileSettings";

export default function UsernameUpdate() {
  const [username, setUsername] = useState("");
  const userdetail = useContext(UserDetailContext);

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setUsername(value);
  };

  // Handle formsubmit
  const nonEmptyFormSubmitCb = (err: any) => {
    if (err) {
      prodLogger.error(err);
      return;
    }

    // Call the name update API handler
    Handler.handleUsernameUpdate(username)
      .then((res) => {
        // Empty the username field
        setUsername("");
      })
      .catch((err) => {
        // Error will be handled in the handler
      });
  };

  return (
    <form
      onSubmit={(e) => {
        Middleware.processFormData({ requiredValues: [username], evt: e }, nonEmptyFormSubmitCb);
      }}
    >
      <div className="flex flex-col gap-2 justify-center md:pr-[74%] md:py-6 py-3 md:px-6">
        <label className="font-bold ">Current username</label>
        <input
          type="text"
          disabled
          value={userdetail.username}
          className="dark:bg-gray-600 outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] "
        />
      </div>

      <div className="flex flex-col gap-2 justify-center md:pr-[70%] md:py-6 md:pb-0 pb-6 md:px-6">
        <label htmlFor={NEW_USERNAME} className="font-bold">
          New username
        </label>
        <em>
          <p className="text-[1rem]">Type your new username</p>
        </em>
        <input
          id={NEW_USERNAME}
          type="text"
          value={username}
          onChange={handleChange}
          autoComplete="off"
          placeholder="Username *"
          className="outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]"
        />
      </div>

      <div className="md:px-6 md:py-6 ">
        <button
          type="submit"
          className="py-[0.85rem] w-full md:w-auto px-4 rounded-xl bg-student-tax-secondary-theme-color hover:bg-white-color text-white hover:text-student-tax-secondary-theme-color font-semibold font-sans"
        >
          Change username
        </button>
      </div>
    </form>
  );
}
