import axiosInstance from "../axios.config";

export default async function getQueryOwner(id: string) {
  try {
    const { data } = await axiosInstance.get(`/admin/auth/ticket_owner_info/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}
