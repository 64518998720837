import React, { useContext, useState } from "react";
import { NEW_EMAIL, PASSWORD, VERIFICATION_CODE } from "../../../utils/constants";
import Password from "../../AuthRelated/Password";
import Middleware from "../../../classes/Middleware";
import Handler from "../../../classes/Handler";
import { prodLogger } from "../../../utils/logger.config";
import { UserDetailContext } from "../../../pages/ProfileSettings";

export default function EmailUpdate() {
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [requiredfields, setRequiredfields] = useState({
    password: "",
    newEmail: "",
    verificationcode: "",
  });
  const userdetail = useContext(UserDetailContext);

  // Handle change in input fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id: type, value } = e.currentTarget;

    switch (type) {
      case PASSWORD:
        setRequiredfields({
          ...requiredfields,
          password: value,
        });
        break;

      case NEW_EMAIL:
        setRequiredfields({
          ...requiredfields,
          newEmail: value,
        });
        break;

      case VERIFICATION_CODE:
        setRequiredfields({
          ...requiredfields,
          verificationcode: value,
        });
        break;

      default:
        break;
    }
  };

  const nonEmptyFormSubmitCb = (
    err: any,
    validatedFields?: {
      email?: string;
      hashedPass?: string;
      phone?: string;
    }
  ) => {
    if (err) {
      prodLogger.error(err);
      return;
    }
    if (validatedFields) {
      if (!isOTPSent) {
        // Send the otp to new email address
        Handler.handleSendingOTPForEmailUpdate({
          password: validatedFields.hashedPass ?? "",
          new_email: validatedFields.email ?? "",
        })
          .then(() => {
            // Set the otp sent state
            setIsOTPSent(true);
          })
          .catch((err) => {
            // Error will be handled in the handler itself
          });
        return;
      }

      // Call the email update API if the otp is sent
      Handler.handleEmailUpdate({
        new_email: validatedFields.email ?? "",
        verification_code: requiredfields.verificationcode,
      })
        .then(() => {
          // Revert to default values
          setRequiredfields({
            password: "",
            newEmail: "",
            verificationcode: "",
          });
          setIsOTPSent(false);
        })
        .catch((errstatus) => {
          // Error will be handled in the handler itself
        });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        !isOTPSent
          ? Middleware.processFormData(
              {
                requiredValues: [requiredfields.newEmail, requiredfields.password],
                evt: e,
                validate: {
                  email: requiredfields.newEmail,
                  password: requiredfields.password,
                },
              },
              nonEmptyFormSubmitCb
            )
          : Middleware.processFormData(
              {
                requiredValues: [
                  requiredfields.newEmail,
                  requiredfields.password,
                  requiredfields.verificationcode,
                ],
                evt: e,
                validate: {
                  email: requiredfields.newEmail,
                  password: requiredfields.password,
                },
              },
              nonEmptyFormSubmitCb
            );
      }}
    >
      <div className="flex gap-6">
        <div className="md:w-[50%] w-full">
          <div className="flex flex-col gap-2 justify-center md:py-6 md:px-6 py-3 md:pb-0 pb-4">
            <label className="font-bold">Current email</label>
            <input
              type="text"
              disabled
              value={userdetail.email}
              className="dark:bg-gray-600 outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)] "
            />
          </div>

          <div className="flex flex-col gap-2 justify-center md:py-6 md:pb-0  pb-4 md:px-6">
            <label htmlFor={PASSWORD} className="font-bold">
              Password
            </label>
            <em>
              <p className="text-[1rem]">Type your password below</p>
            </em>
            <Password
              id={PASSWORD}
              onChange={handleChange}
              value={requiredfields.password}
              placeholder="Password *"
            />
          </div>

          <div className="flex flex-col gap-2 justify-center md:pb-0 pb-4 md:py-6 md:px-6">
            <label htmlFor={NEW_EMAIL} className="font-bold">
              New email
            </label>
            <em>
              <p className="text-[1rem]">Type your new email below</p>
            </em>
            <input
              id={NEW_EMAIL}
              type="text"
              value={requiredfields.newEmail}
              onChange={handleChange}
              autoComplete="off"
              placeholder="Email *"
              className="outline-none ring-blue-300 focus:ring-2 focus:border-none border-none shadow-[inset_2px_2px_5px_rgba(0,0,0,0.1),_4px_4px_8px_rgba(0,0,0,0.1)]"
            />
          </div>
        </div>

        {isOTPSent && (
          <div className="flex flex-col gap-2 justify-start py-6 px-6 w-[50%]">
            <label htmlFor={VERIFICATION_CODE} className="font-bold">
              Verification code
            </label>
            <em>
              <p className="text-[1rem]">Type the verification code below</p>
            </em>
            <input
              id={VERIFICATION_CODE}
              type="text"
              value={requiredfields.verificationcode}
              onChange={handleChange}
              autoComplete="off"
              placeholder="Verification code *"
            />
          </div>
        )}
      </div>

      <div className="md:px-6 py-6 ">
        {!isOTPSent ? (
          <button
            type="submit"
            className="w-full md:w-auto py-[0.85rem] px-4 rounded-xl bg-student-tax-secondary-theme-color hover:bg-white-color text-white hover:text-student-tax-secondary-theme-color font-semibold font-sans"
          >
            Send verification code
          </button>
        ) : (
          <button
            type="submit"
            className=" w-full md:w-auto py-[0.85rem] px-4 rounded-xl bg-student-tax-secondary-theme-color hover:bg-white-color text-white hover:text-student-tax-secondary-theme-color font-semibold font-sans"
          >
            Change email
          </button>
        )}
      </div>
    </form>
  );
}
