import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  ADMIN_DASHBOARD,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_LOGIN,
  CART,
  CONTACT_US,
  FORGOT_PASSWORD,
  HISTORY,
  HOME,
  LOGIN,
  PROFILE_SETTINGS,
  REGISTER,
  SUPPORT,
  VERIFY_EMAIL,
} from "./utils/routes";
import Login from "./pages/Login";
import { useLayoutEffect, useEffect } from "react";
import User from "./classes/User";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Register from "./pages/Register";
import Handler from "./classes/Handler";
import ToastMessageObj from "./interfaces/ToastMessageObj.interface";
import Toast from "./components/Decorations/Toast";
import { devLogger } from "./utils/logger.config";
import Disabled from "./components/Decorations/Disabled";
import ForgotPassword from "./pages/ForgotPassword";
import ContactUs from "./pages/ContactUs";
import ProfileSettings from "./pages/ProfileSettings";
import Middleware from "./classes/Middleware";
import { useAdminAuthentication } from "./utils/customHooks";
import AdminLogin from "./pages/AdminRelated/AdminLogin";
import AdminLoginHandler from "./utils/api/AdminLoginHandler";
import AdminDashboard from "./pages/AdminRelated/AdminDashboard";
import Page404 from "./pages/Page404";
import { ADMIN_ACCOUNT, USER_ACCOUNT } from "./utils/constants";
import CookiePolicy from "./components/cookies/CookiePolicy";
import Activation from "./pages/VerificationEmail/Activation";
import History from "./pages/History";
import Cart from "./pages/Cart";
import { initGA, logPageView } from "./googleAnalytics/ga";
import routePageTitleMap from "./utils/RoutePageTitleMap";
import LandingPage from "./pages/LandingPage";
import SupportPage from "./pages/SupportPage";

function App() {
  const isUserAuthenticated = useSelector((state: any) => state.isUserAuthenticated);
  const theme = useSelector((state: any) => state.theme);
  const toast: ToastMessageObj = useSelector((state: any) => state.toastMessage);
  const disableScreen: boolean = useSelector((state: any) => state.disableScreen);
  const dispatch = useDispatch();
  const location = useLocation();
  const { adminAuthenticated } = useAdminAuthentication();

  devLogger.log(`Disable screen: ${disableScreen}`);

  // Authorize user
  useLayoutEffect(() => {
    User.authorize(dispatch);
  }, [dispatch]);

  // Initialize global necessary dispatches
  useEffect(() => {
    Handler.dispatch = dispatch;
    Middleware.dispatch = dispatch;
    AdminLoginHandler.setDispatch(dispatch);
  }, [dispatch]);
  useEffect(() => {
    const mesurmentKey = process.env.REACT_APP_GOOGLE_ANALYTICS_MESURMENT_KEY;
    // Initialize Google Analytics (use your own Measurement ID here)
    if (!mesurmentKey) {
      return;
    }
    initGA(mesurmentKey); // Replace with your actual Google Analytics Measurement ID
  }, []);
  useEffect(() => {
    const pageTitle = routePageTitleMap[location.pathname] || "Taxmate";
    document.title = pageTitle;

    // Send the updated page view to Google Analytics
    logPageView(location.pathname, pageTitle); // Assuming logPageView sends page path to GA
  }, [location]);

  return (
    <main className={theme}>
      {isUserAuthenticated !== null && (
        <main className="relative min-h-[100vh] bg-body-color">
          {disableScreen && <Disabled />}
          <Navbar />
          {toast.toDisplay && <Toast message={toast.message} type={toast.type} />}
          <Routes>
            <Route
              path={LOGIN}
              element={!isUserAuthenticated ? <Login /> : <Navigate to={HOME} />}
            />
            <Route
              path={REGISTER}
              element={!isUserAuthenticated ? <Register /> : <Navigate to={HOME} />}
            />
            <Route path={VERIFY_EMAIL} element={<Activation />} />
            <Route
              path={FORGOT_PASSWORD}
              element={
                !isUserAuthenticated ? (
                  <ForgotPassword accountType={USER_ACCOUNT} />
                ) : (
                  <Navigate to={HOME} />
                )
              }
            />

            {/* After login routes */}
            <Route path={HOME} element={isUserAuthenticated ? <Home /> : <Navigate to="/" />} />
            <Route
              path={PROFILE_SETTINGS}
              element={isUserAuthenticated ? <ProfileSettings /> : <Navigate to={LOGIN} />}
            />
            <Route
              path={HISTORY}
              element={isUserAuthenticated ? <History /> : <Navigate to={LOGIN} />}
            />
            <Route path={CART} element={isUserAuthenticated ? <Cart /> : <Navigate to={LOGIN} />} />
            <Route
              path={SUPPORT}
              element={isUserAuthenticated ? <SupportPage /> : <Navigate to={LOGIN} />}
            />

            {/* Info related routes */}
            <Route path={CONTACT_US} element={<ContactUs />} />

            {/* Admin routes */}
            <Route
              path={ADMIN_LOGIN}
              element={!adminAuthenticated ? <AdminLogin /> : <Navigate to={ADMIN_DASHBOARD} />}
            />
            <Route
              path={ADMIN_DASHBOARD}
              element={adminAuthenticated ? <AdminDashboard /> : <Navigate to={ADMIN_LOGIN} />}
            />
            <Route
              path={ADMIN_FORGOT_PASSWORD}
              element={
                !adminAuthenticated ? (
                  <ForgotPassword accountType={ADMIN_ACCOUNT} />
                ) : (
                  <Navigate to={ADMIN_DASHBOARD} />
                )
              }
            />

            {/* 404 */}
            <Route path="*" element={<Page404 />} />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </main>
      )}
      <CookiePolicy />
    </main>
  );
}

export default App;
