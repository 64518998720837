import React from "react";
import EasySteps from "../components/landingPage/EasySteps";
import WhyToChoose from "../components/landingPage/WhyToChoose";
import FAQSection from "../components/landingPage/FAQSection";
import AppSection from "../components/landingPage/AppSection";
import Footer from "../components/Footer";

function LandingPage() {
  return (
    <div>
      <EasySteps />
      <WhyToChoose />
      <FAQSection />
      <AppSection />
      <Footer />
    </div>
  );
}

export default LandingPage;
