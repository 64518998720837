import React from "react";
import AdminAuth from "../../classes/AdminAuth";
import lang from "../../utils/LanguagePacks/en-us.json";
export default function AdminNavbar() {
  const handleLogout = () => {
    const adminAuth = new AdminAuth(); // Create an instance of AdminAuth
    adminAuth.logoutAdmin();
  };
  return (
    <div className="bg-student-tax-secondary-theme-color  px-7 py-2 rounded" onClick={handleLogout}>
      {lang.logout}
    </div>
  );
}
