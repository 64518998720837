export const LOGIN = "/login";
export const REGISTER = "/register";
export const HOME = "/home";
export const LANDING_PAGE = "/";
export const FORGOT_PASSWORD = "/forgot-password";
export const VERIFY_EMAIL = "/activate/:token";

// Info related
export const CONTACT_US = "/contact-us";
export const HISTORY = "/history";
export const CART = "/cart";
export const SUPPORT = "/support";
// Profile related
export const PROFILE_SETTINGS = "/profile/settings";

// Admin routes
export const ADMIN_LOGIN = `/admin-portal/login/${process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""}`;
export const ADMIN_DASHBOARD = `/admin-portal/dashboard/${
  process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""
}`;
export const ADMIN_FORGOT_PASSWORD = `/admin-portal/${FORGOT_PASSWORD}/${process.env.REACT_APP_ADMIN_SECRET_CODE ?? ""}`;
