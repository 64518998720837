const truncateText = (
  query: string,
  length: number
): { displayText: string; seeMoreNeeded: boolean } => {
  if (query.length > length) {
    return {
      displayText: query.slice(0, length) + "...", // Truncate and add ellipsis
      seeMoreNeeded: true, // Flag to show "See more"
    };
  }
  return {
    displayText: query, // Full text (no truncation)
    seeMoreNeeded: false, // No truncation needed
  };
};

export default truncateText;
