import axiosInstance from "../axios.config";
import { devLogger } from "../logger.config";

export default async function ChangeTicketStatusAdmin(id: string, data1: { [key: string]: any }) {
  try {
    const { data } = await axiosInstance.patch(`/admin/auth/change_ticket_status/${id}`, data1);
    devLogger.log(data);
    return data;
  } catch (error) {
    devLogger.error(error);
    throw error;
  }
}
